import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Hero from "../components/home/hero";
import OurImpact from "../components/home/ourImpact";
import InterestSection from "../components/home/interestSection";
import Clients from "../components/home/clients";
import FocusAreaSection from "../components/home/focusAreaSection";
import VertiSlide from "../components/home/vertiSlide";
import ValueSection from "../components/home/valueSection";
import NewsSection from "../components/home/newsSection";
import Testimonials from "../components/home/testimonials";
import PortfolioSection from "../components/home/portfolioSection";

export default function Home() {
  const { hash } = useLocation();
  const navigate = useNavigate();

  // Create refs for each section
  const impactSectionRef = useRef(null);
  const focusAreaSectionRef = useRef(null);
  const portfolioSectionRef = useRef(null);
  const valueSectionRef = useRef(null);
  const testimonialsSectionRef = useRef(null);
  
  // Map hash values to refs
  const refMap = {
    '#impact': impactSectionRef,
    '#focusarea': focusAreaSectionRef,
    '#portfolio': portfolioSectionRef,
    '#value': valueSectionRef,
    '#testimonials': testimonialsSectionRef,
  };

  useEffect(() => {
    if (hash && refMap[hash]) {
      const scrollToSection = () => {
        const section = refMap[hash].current;
        if (section) {
          // Scroll to the section with a slight delay to account for any layout shifts
          setTimeout(() => {
            section.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }, 500); // Adjust delay as needed
        }
      };

      // Trigger the scroll on page load and after a short delay
      scrollToSection();
      setTimeout(scrollToSection, 500); // Additional scroll after a delay to ensure positioning
    }
  }, [hash]);

  return (
    <>
      <Hero />
      {/* <div ref={impactSectionRef}>
        <OurImpact />
      </div> */}
      <InterestSection />
      <Clients />
      <div ref={focusAreaSectionRef}>
        <FocusAreaSection />  
      </div>
      {/* <VertiSlide /> */}
      <div ref={portfolioSectionRef}>
      <VertiSlide />
      <PortfolioSection />
      </div>
      <div ref={valueSectionRef}>
        <ValueSection />
      </div>
      {/* <NewsSection />
      <div ref={testimonialsSectionRef}>
        <Testimonials />
      </div> */}
    </>
  );
}
