// "use client";
import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import IntegrityLeft from "./../../assets/images/about/integrity-left.png";
import IntegrityRight from "./../../assets/images/about/integrity-right.png";
import integrity1 from "./../../assets/videos/integrity1.mp4";
import integrity2 from "./../../assets/videos/integrity2.mp4";

const tabData = [
  {
    title: "Integrity",
    content:
      "It's the virtue in our veins, we love doing the right thing, when everyone is looking up to us, and we love doing the right thing a lot more, when no one is watching. we are the people of SKCL accelerator.",
    imgLeft: IntegrityLeft,
    imgRight: IntegrityRight,
  },
  {
    title: "Opportunity",
    content:
      "It's the launchpad that catapults everything, from paper planes to spaceships, and we believe that it is our moral duty to create it for everyone that crosses our path. Opportunity, we know how to find it, share it, leverage it and create it.",
    imgLeft: IntegrityLeft,
    imgRight: IntegrityRight,
  },
  {
    title: "Empathy",
    content:
      "To us life is about finding our tribe, most often that comes with a whole lot of empathy and a sprinkle of vibe, you will always find a friend in us, because we too have been through the grind.",
    imgLeft: IntegrityLeft,
    imgRight: IntegrityRight,
  },
  {
    title: "Innovation",
    content:
      "Innovation is not thinking outside the box, innovation happens when you think like there is no box. So come fail, fail forward , fail fast, stand up, learn and innovate. because innovation happens when you fall down seven and stand up eight.",
    imgLeft: IntegrityLeft,
    imgRight: IntegrityRight,
  },
];

const Slider = () => {
  const [activeTab, setActiveTab] = useState(tabData[0].title);

  const activeTabContent = tabData.find((tab) => tab.title === activeTab);

  return (
    <div className="relative flex flex-col rounded-[64px] px-5 lg:px-24 pb-[56px] h-[710px] bg-[#a6b6c4] z-10 overflow-hidden">
      <AnimatePresence mode="wait">
        <motion.div
          key={activeTab}
          initial={{ opacity: 0, y: 200 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 200 }}
          transition={{ duration: 0.4 }}
          className="absolute bottom-[-190px] left-[-160px] z-[-1]">
          <video
            autoPlay
            muted
            loop
            playsInline
            controlsList="nodownload"
            controls={false}
            preload="none"
            className="w-[500px] ">
            <source src={integrity2} type="video/mp4" playsInline />
          </video>
        </motion.div>
      </AnimatePresence>

      <AnimatePresence mode="wait">
        <motion.div
          key={activeTab}
          initial={{ opacity: 0, y: -200 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -200 }}
          transition={{ duration: 0.4 }}
          className="absolute top-[-130px] right-[-120px] z-[-1]">
          <video
            autoPlay
            muted
            loop
            playsInline
            controlsList="nodownload"
            controls={false}
            preload="none"
            className="w-[500px] ">
            <source src={integrity1} type="video/mp4" playsInline />
          </video>
        </motion.div>
      </AnimatePresence>
      <div className="flex flex-col items-center justify-center h-full">
        <AnimatePresence mode="wait">
          <motion.div
            key={activeTab}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}>
            <h2 className="text-[40px] leading-[56px] pb-6">
              {activeTabContent.title}
            </h2>
            <p className="w-[270px] md:w-[480px] lg:w-[560px] text-base md:text-[20px] md:leading-[36px]">
              {activeTabContent.content}
            </p>
          </motion.div>
        </AnimatePresence>
      </div>
      <div className="relative arrow-right md:hidden">
        {/* <img className="absolute w-[36px] right-0 bottom-[20px]" src={ArrowRight} alt="Skcl" /> */}
        <svg
          className="absolute w-[36px] right-0 bottom-[20px]"
          xmlns="http://www.w3.org/2000/svg"
          width="36"
          height="20"
          viewBox="0 0 18 17"
          fill="none">
          <path
            d="M0.700562 8.50465H16.4648M16.4648 8.50465L8.48408 0.523926M16.4648 8.50465L8.48408 16.4854"
            stroke="#595959"
            stroke-width="0.864067"
            stroke-linecap="round"
          />
        </svg>
      </div>
      <div className="flex justify-between gap-10 overflow-scroll no-scrollbar">
        {tabData.map((tab) => (
          <button
            key={tab.title}
            onClick={() => setActiveTab(tab.title)}
            className={`text-[22px] leading-normal font-medium border-t-[1px] border-[#A4A4A4] ${
              activeTab === tab.title ? "text-[#222]" : "text-[#4F4F4F]"
            }`}>
            {tab.title}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Slider;
