import { Link } from "react-router-dom";
import React from "react";
import CircledArrowSvg from "../components/circledArrowSvg";

const NotFound = () => {
  return (
    <div className="flex flex-col justify-center items-center h-[50vh] bg-[#97A8B4] gap-4 px-10">
      <h1 className="text-2xl md:text-3xl">404. Page not found.</h1>
      <Link href="/" className="flex items-center gap-4">
        <div className="rotate-[180deg]">
          <CircledArrowSvg />
        </div>
        Back to Home
      </Link>
    </div>
  );
};

export default NotFound;
