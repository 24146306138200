// // "use client";
import React, { useState, useEffect } from "react";
import axios from "axios";
import Api from "../../package.json";
// import Head from "next/head";
// import Image from "next/image";
import Herobanner from "./../assets/videos/clientele-banner.mp4";
import SlideUp from "../components/slideUp";
import DottedBg from "./../assets/images/common/clients-dotted-pattern.png";
import Abb from "./../assets/images/clients/abb.svg";
import Alhua from "./../assets/images/clients/alhua.svg";
import Assetplus from "./../assets/images/clients/assetplus.svg";
import Bajaj from "./../assets/images/clients/bajaj.svg";
import Balcke from "./../assets/images/clients/balcke.svg";
import Bahri from "./../assets/images/clients/bahri.svg";
import Bsb from "./../assets/images/clients/bsb.svg";
import Canon from "./../assets/images/clients/canon.svg";
import Cashkaro from "./../assets/images/clients/cashkaro.svg";
import Cisco from "./../assets/images/clients/cisco.svg";
import Cnim from "./../assets/images/clients/martin.svg";
import Colliers from "./../assets/images/clients/colliers.svg";
import Congruent from "./../assets/images/clients/congruent.svg";
import Cooper from "./../assets/images/clients/cooper.svg";
import Eql from "./../assets/images/clients/eql.svg";
import Equitas from "./../assets/images/clients/equitas.svg";
import Ewt from "./../assets/images/clients/ewt.svg";
import Fedex from "./../assets/images/clients/fedex.svg";
import Fleet from "./../assets/images/clients/fleet.svg";
import Harting from "./../assets/images/clients/harting.svg";
import Hindus from "./../assets/images/clients/hindus.svg";
import Hitachi from "./../assets/images/clients/hitachi.svg";
import Iff from "./../assets/images/clients/iff.svg";
import Imshealth from "./../assets/images/clients/imshealth.svg";
import IndiaRating from "./../assets/images/clients/india-rating.svg";
import indiqube from "./../assets/images/clients/indiqube.svg";
import Ingram from "./../assets/images/clients/ingram.svg";
import Innov from "./../assets/images/clients/innov.svg";
import Inorya from "./../assets/images/clients/inorya.svg";
import Iqvia from "./../assets/images/clients/iqvia.svg";
import Itdata from "./../assets/images/clients/itdata.svg";
import Keyence from "./../assets/images/clients/keyence.svg";
import kns from "./../assets/images/clients/kns.svg";
import Ledgers from "./../assets/images/clients/ledgers.svg";
import Linxon from "./../assets/images/clients/linxon.svg";
import LtIdpl from "./../assets/images/clients/lt-idpl.svg";
import Malladi from "./../assets/images/clients/malladi.svg";
import Martin from "./../assets/images/clients/martin.svg";
import MeEngineer from "./../assets/images/clients/me-engineers.svg";
import Medsmart from "./../assets/images/clients/medsmart.svg";
import Metso from "./../assets/images/clients/metso.svg";
import Montra from "./../assets/images/clients/montra.svg";
import Nkt from "./../assets/images/clients/nkt.svg";
import Ntt from "./../assets/images/clients/ntt.svg";
import Nucleus from "./../assets/images/clients/nucleus.svg";
import One from "./../assets/images/clients/one.svg";
import Pothys from "./../assets/images/clients/pothys.svg";
import Redgrape from "./../assets/images/clients/redgrape.svg";
import Roche from "./../assets/images/clients/redgrape.svg";
import Savills from "./../assets/images/clients/savills.svg";
import Sharekhan from "./../assets/images/clients/sharekhan.svg";
import Siemens from "./../assets/images/clients/siemens.svg";
import Skf from "./../assets/images/clients/skf.svg";
import Softcell from "./../assets/images/clients/softcell.svg";
import Stahl from "./../assets/images/clients/stahl.svg";
import Sulzer from "./../assets/images/clients/sulzer.svg";
import Symbiance from "./../assets/images/clients/symbiance.svg";
import UnifiedVoice from "./../assets/images/clients/unified-voice-comm.svg";
import Valmet from "./../assets/images/clients/valmet.svg";
import Veritas from "./../assets/images/clients/veritas.svg";
import Vicoma from "./../assets/images/clients/vicoma.svg";
import Yokagawa from "./../assets/images/clients/yokogawa.svg";
import Zwick from "./../assets/images/clients/zwick.svg";

// export const metadata = {
//   title: "Clientele | SKCL",
//   description: "Created By FPF",
// };

const Clientele = () => {
  const [clientDatas, setClientDatas] = useState([]);
  const clientData = [
    {
      imgUrl: Abb,
      companyName: "ABB Group",
    },
    {
      imgUrl: Assetplus,
      companyName: "Asset Plus",
    },
    {
      imgUrl: Bajaj,
      companyName: "Bajaj Finance Limited",
    },
    {
      imgUrl: Balcke,
      companyName: "Balcke Durr",
    },
    {
      imgUrl: Bsb,
      companyName: "BS&B Technologies",
    },
    {
      imgUrl: Canon,
      companyName: "Canon",
    },
    {
      imgUrl: Cashkaro,
      companyName: "Cashkaro",
    },
    {
      imgUrl: Cisco,
      companyName: "CISCO Systems",
    },
    {
      imgUrl: Cnim,
      companyName: "CNIM Martin",
    },
    {
      imgUrl: Colliers,
      companyName: "Colliers International",
    },
    {
      imgUrl: Congruent,
      companyName: "Congruent Solutions",
    },
    {
      imgUrl: Cooper,
      companyName: "Cooper Standard",
    },
    {
      imgUrl: Alhua,
      companyName: "Dahua",
    },
    {
      imgUrl: Ewt,
      companyName: "Emergaya wind Turbines",
    },
    {
      imgUrl: Eql,
      companyName: "EQL Financial Technologies",
    },
    {
      imgUrl: Equitas,
      companyName: "Equitas Bank",
    },
    {
      imgUrl: Equitas,
      companyName: "Espace Network",
    },
    {
      imgUrl: Fedex,
      companyName: "Fedex",
    },
    {
      imgUrl: Fleet,
      companyName: "Fleet Management",
    },
    {
      imgUrl: Harting,
      companyName: "Harting",
    },
    {
      imgUrl: Hindus,
      companyName: "Hindustan Coco Cola Beverages",
    },
    {
      imgUrl: Hitachi,
      companyName: "Hitachi",
    },
    {
      imgUrl: Imshealth,
      companyName: "IMS Health",
    },
    {
      imgUrl: IndiaRating,
      companyName: "India Ratings",
    },
    {
      imgUrl: indiqube,
      companyName: "Indiqube",
    },
    {
      imgUrl: Ingram,
      companyName: "Ingram Micro",
    },
    {
      imgUrl: Innov,
      companyName: "Innov8",
    },
    {
      imgUrl: Inorya,
      companyName: "Inorya",
    },
    {
      imgUrl: Iff,
      companyName: "International Flavours & Fragrances",
    },
    {
      imgUrl: Iqvia,
      companyName: "IQVIA Consulting",
    },
    {
      imgUrl: Itdata,
      companyName: "ITI Data",
    },
    {
      imgUrl: Itdata,
      companyName: "K&S Partners",
    },
    {
      imgUrl: Keyence,
      companyName: "Keyence",
    },
    {
      imgUrl: Ledgers,
      companyName: "Ledgers",
    },
    {
      imgUrl: Linxon,
      companyName: " Linxon",
    },
    {
      imgUrl: LtIdpl,
      companyName: "LTIDPL Indvit Services ",
    },
    {
      imgUrl: Malladi,
      companyName: "Malldi Drugs & Pharmaceuticals",
    },
    {
      imgUrl: MeEngineer,
      companyName: "ME Engineers",
    },
    {
      imgUrl: Medsmart,
      companyName: "Medsmart",
    },
    {
      imgUrl: Metso,
      companyName: "Metso Outotec",
    },
    {
      imgUrl: Nkt,
      companyName: "NKT",
    },
    {
      imgUrl: Ntt,
      companyName: "NTT India Digital Business",
    },
    {
      imgUrl: Nucleus,
      companyName: "Nucleus Software",
    },
    {
      imgUrl: One,
      companyName: "Nucleus Software - Ocean Network Express ",
    },
    {
      imgUrl: Pothys,
      companyName: "Pothys",
    },
    {
      imgUrl: Redgrape,
      companyName: "Red Grape Business Services",
    },
    {
      imgUrl: Roche,
      companyName: "Roche Diagnostics",
    },
    {
      imgUrl: Savills,
      companyName: " Savills ",
    },
    {
      imgUrl: Sharekhan,
      companyName: "Sharekhan",
    },
    {
      imgUrl: Siemens,
      companyName: "Siemens",
    },
    {
      imgUrl: Skf,
      companyName: "SKF",
    },
    {
      imgUrl: Softcell,
      companyName: "Softcell",
    },
    {
      imgUrl: Stahl,
      companyName: "Stahl",
    },
    {
      imgUrl: Sulzer,
      companyName: "Sulzer Pumps",
    },
    {
      imgUrl: Symbiance,
      companyName: "Symbiance",
    },
    {
      imgUrl: Bahri,
      companyName: "The National Shipping Company of Saudi Arabia (Bahri)",
    },
    {
      imgUrl: Montra,
      companyName: "TI Clean Mobility Montra Electric ",
    },
    {
      imgUrl: UnifiedVoice,
      companyName: "Unified Voice Communication",
    },
    {
      imgUrl: Valmet,
      companyName: "Valmet Technologies",
    },
    {
      imgUrl: Veritas,
      companyName: "Veritas Finance",
    },
    {
      imgUrl: Vicoma,
      companyName: "Vicoma",
    },
    {
      imgUrl: Yokagawa,
      companyName: "Yokogawa",
    },
    {
      imgUrl: Zwick,
      companyName: "Zwick Roel",
    },
  ];
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
  };

  const get_load_data = async () => {
 
   const response1 = await axios({
     method: "GET",
     url: Api.userdata.url + "get_client_details",
     
     headers: headers,
   });
   const json1 = await response1.data;
   if (json1["status"] == "OK") {  

    setClientDatas(json1["message"]);
   }
   
   

  }; 

  useEffect(() => {

    get_load_data();

  }, []);
  return (
    <>
      {/* <Head>
        <title>Clientele Page</title>
        <meta name="description" content="This is a Clientele page" />
        <meta name="keywords" content="clientele, nextjs, react" />
      </Head> */}

      {/* Hero Section */}
      <div className="relative overflow-hidden flex flex-col items-center justify-center gap-3 bg-[#C5F36D] rounded-tl-[40px] md:rounded-tl-[80px] rounded-tr-[40px] md:rounded-tr-[80px] h-[450px] md:h-[512px] z-10">
        <SlideUp custom={0.4}>
          <h1 className="text-3xl md:text-[40px] md:leading-normal font-light text-center">
            Our Clientele
          </h1>
        </SlideUp>
        <video
          autoPlay
          muted
          loop
          playsInline
          controlsList="nodownload"
          controls={false}
          preload="none"
          className="absolute h-full w-full z-[-1] scale-[3] md:scale-150 md:mb-20">
          <source src={Herobanner} type="video/mp4" playsInline />
        </video>
      </div>

      {/* Clients Section */}
      <div className="relative overflow-hidden clientele-dotted-bg">
        {/* <img
          src={DottedBg}
          alt="dotted-bg"
          className="absolute top-0 z-[-1] opacity-70 scale-[2] md:scale-100"
        /> */}
        <SlideUp custom={0.5}>
          <div className="relative container max-w-[1400px] mx-auto grid items-center grid-cols-2 gap-10 xl:gap-24 px-5 py-16 lg:py-32 md:grid-cols-3 xl:grid-cols-4">
            {clientDatas && clientDatas.map(({ imgUrl, companyName }) => (
              <div className="flex flex-col items-center gap-7">
                <img
                  src={imgUrl}
                  alt="client-logo"
                  className="object-contain"
                />
                <p className="text-center">{companyName}</p>
              </div>
            ))}
          </div>
        </SlideUp>
      </div>
    </>
  );
};

export default Clientele;
