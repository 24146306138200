// "use client";
import React from "react";
// import Image from "next/image";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import ResoucesImg from "./../../assets/images/home/resource-img.png";
import CoWorkingImg from "./../../assets/images/home/coworking-space.png";
import NetworkImg from "./../../assets/images/home/network-events.png";
import GrantsImg from "./../../assets/images/home/grants.png";
import IpSupportImg from "./../../assets/images/home/ip-support.png";
import MembershipImg from "./../../assets/images/home/memship-img.png";
import FundingImg from "./../../assets/images/home/funding.png";
import IndustryConnectImg from "./../../assets/images/home/industry-connect.png";
import ValueBg1 from "./../../assets/images/home/value-bg1.png";
import ValueBg2 from "./../../assets/images/home/value-bg2.png";
import ValueBg1Sm from "./../../assets/images/home/value-left-sm.png";
import ValueBg2Sm from "./../../assets/images/home/value-right-sm.png";
import SlideUp from "../slideUp";
import ValueVideo from "./../../assets/videos/Set2.mp4";
import ValueVideo2 from "./../../assets/videos/value-left.mp4";

const ValueSection = () => {
  const valueArrSm = [
    {
      imgSrc: ResoucesImg,
      title: "Resources",
    },
    {
      imgSrc: FundingImg,
      title: "Fund Raising",
    },
    {
      imgSrc: CoWorkingImg,
      title: "Co-working Space",
    },
    {
      imgSrc: IndustryConnectImg,
      title: "Industry Connect",
    },
    {
      imgSrc: NetworkImg,
      title: "Networking Events",
    },
    {
      imgSrc: GrantsImg,
      title: "Grants",
    },
    {
      imgSrc: IpSupportImg,
      title: "IP Support",
    },
    {
      imgSrc: MembershipImg,
      title: "Membership",
    },
  ];

  const valueArr = [
    {
      imgSrc: ResoucesImg,
      title: "Resources",
    },
    {
      imgSrc: FundingImg,
      title: "Fund Raising",
    },
    {
      imgSrc: CoWorkingImg,
      title: "Co-working Space",
    },
    {
      imgSrc: IndustryConnectImg,
      title: "Industry Connect",
    },
    {
      imgSrc: NetworkImg,
      title: "Networking Events",
    },
    {
      imgSrc: GrantsImg,
      title: "Grants",
    },
    {
      imgSrc: IpSupportImg,
      title: "IP Support",
    },
    {
      imgSrc: MembershipImg,
      title: "Membership",
    },
  ];

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  return (
    <div
      className="w-full pt-16 pb-36 px-5 lg:px-[70px] bg-[#97A8B4] relative overflow-hidden">
      <video
        autoPlay
        muted
        loop
        playsInline
        controlsList="nodownload"
        controls={false}
        preload="none"
        className="absolute top-[-60px] md:top-[-100px] xl:top-[-115px] left-[-100px] md:left-[-180px] w-[420px] md:w-[500px] lg:w-[600px] xl:w-[760px]">
        <source src={ValueVideo2} type="video/mp4" playsInline />
      </video>

      <video
        autoPlay
        muted
        loop
        playsInline
        controlsList="nodownload"
        controls={false}
        preload="none"
        className="absolute bottom-[-60px] md:bottom-[-100px] xl:bottom-[-115px] right-[-100px] md:right-[-180px] w-[420px] md:w-[500px] lg:w-[600px] xl:w-[680px]">
        <source src={ValueVideo} type="video/mp4" playsInline />
      </video>

      <SlideUp custom={0.3}>
        <h1 className="text-[28px] lg:text-[40px] lg:leading-[48px] font-light text-center pb-[68px] z-[10] relative">
          How we add value
        </h1>
      </SlideUp>
      {/* For < 768 */}
      <div className="grid items-start grid-cols-2 gap-8 md:hidden" ref={ref}>
        {valueArrSm.map(({ imgSrc, title }, i) => (
          <SlideUp custom={0.3 * i}>
            <motion.div
              className="relative even:pt-20"
              key={i}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                duration: 0.5,
              }}>
              {imgSrc && (
                <img
                  src={imgSrc}
                  alt="value-img"
                  className="rounded-lg h-[166px] w-[180px] object-cover"
                />
              )}
              {title && (
                <p className="absolute bottom-0 left-0 text-[18px] pl-[10px] pb-[10px] text-white">
                  {title}
                </p>
              )}
            </motion.div>
          </SlideUp>
        ))}
      </div>

      {/* For >= 768  */}
      <div
        className="container max-w-[1440px] mx-auto hidden grid-cols-3 gap-8 md:grid"
        ref={ref}>
        {valueArr.map(({ imgSrc, title }, i) => (
          <motion.div
            className="relative"
            key={i}
            initial={{ opacity: 0 }}
            animate={{ opacity: inView ? 1 : 0 }}
            transition={{
              duration: 0.5,
              delay: inView ? Math.random() * 1.5 : 0,
            }}>
            {imgSrc && (
              <img src={imgSrc} alt="value-img" className="rounded-lg" />
            )}
            {title && (
              <p className="absolute bottom-0 left-0 text-[19px] leading-[24px] lg:text-[24px] lg:leading-normal pl-[22px] pb-[20px] text-white">
                {title}
              </p>
            )}
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default ValueSection;
