import React from "react";
import ClientMarquee1 from "./clientMarquee";
import ClientMarquee2 from "./clientMarquee2";
// import Image from "next/image";
import PortfolioBgSm from "./../../assets/images/home/portfolio-bg-sm.png";
import PortfolioBg from "./../../assets/images/home/portfolio-bg.svg";
import DottedBg from "./../../assets/images/home/dotted-bg-sm.png";

const PortfolioSection = () => {
  return (
    <div className="relative pb-32 lg:hidden">
      <img
        src={DottedBg}
        className="absolute w-full lg:hidden"
        alt="dotted-bg"
      />
      <img
        src={PortfolioBgSm}
        alt="portfolio-bg"
        className="md:hidden absolute top-[-90px]"
      />
      <img
        src={PortfolioBg}
        alt="portfolio-bg"
        className="absolute top-0 hidden md:block"
      />
      <h1 className="text-[28px] pb-28 px-5 font-light">Our Portfolio</h1>
      <div className="relative flex flex-col gap-10 overflow-hidden">
        <ClientMarquee1 />
        <ClientMarquee2 />
      </div>
    </div>
  );
};

export default PortfolioSection;
