import React, { useState, useEffect } from "react";
import axios from "axios";
import Api from "../../../package.json";
import { Link } from "react-router-dom";
import AnimatedLogos from "./animatedLogos";
import Abb from "./../../assets/images/clients/abb.svg";
import Alhua from "./../../assets/images/clients/alhua.svg";
import Assetplus from "./../../assets/images/clients/assetplus.svg";
import Bajaj from "./../../assets/images/clients/bajaj.svg";
import Balcke from "./../../assets/images/clients/balcke.svg";
import Bsb from "./../../assets/images/clients/bsb.svg";
import Canon from "./../../assets/images/clients/canon.svg";
import Cashkaro from "./../../assets/images/clients/cashkaro.svg";
import Cisco from "./../../assets/images/clients/cisco.svg";
import Cnim from "./../../assets/images/clients/martin.svg";
import Colliers from "./../../assets/images/clients/colliers.svg";
import Congruent from "./../../assets/images/clients/congruent.svg";
import Cooper from "./../../assets/images/clients/cooper.svg";
import Eql from "./../../assets/images/clients/eql.svg";
import Equitas from "./../../assets/images/clients/equitas.svg";
import Ewt from "./../../assets/images/clients/ewt.svg";
import Fedex from "./../../assets/images/clients/fedex.svg";
import Fleet from "./../../assets/images/clients/fleet.svg";
import Harting from "./../../assets/images/clients/harting.svg";
import Hindus from "./../../assets/images/clients/hindus.svg";
import Hitachi from "./../../assets/images/clients/hitachi.svg";
import Iff from "./../../assets/images/clients/iff.svg";
import Imshealth from "./../../assets/images/clients/imshealth.svg";
import IndiaRating from "./../../assets/images/clients/india-rating.svg";
import indiqube from "./../../assets/images/clients/indiqube.svg";
import Ingram from "./../../assets/images/clients/ingram.svg";
import Innov from "./../../assets/images/clients/innov.svg";
import Inorya from "./../../assets/images/clients/inorya.svg";
import Iqvia from "./../../assets/images/clients/iqvia.svg";
import Itdata from "./../../assets/images/clients/itdata.svg";
import Keyence from "./../../assets/images/clients/keyence.svg";
import kns from "./../../assets/images/clients/kns.svg";
import Ledgers from "./../../assets/images/clients/ledgers.svg";
import Linxon from "./../../assets/images/clients/linxon.svg";
import LtIdpl from "./../../assets/images/clients/lt-idpl.svg";
import Malladi from "./../../assets/images/clients/malladi.svg";
import Martin from "./../../assets/images/clients/martin.svg";
import MeEngineer from "./../../assets/images/clients/me-engineers.svg";
import Medsmart from "./../../assets/images/clients/medsmart.svg";
import Metso from "./../../assets/images/clients/metso.svg";
import Montra from "./../../assets/images/clients/montra.svg";
import Nkt from "./../../assets/images/clients/nkt.svg";
import Ntt from "./../../assets/images/clients/ntt.svg";
import Nucleus from "./../../assets/images/clients/nucleus.svg";
import One from "./../../assets/images/clients/one.svg";
import Pothys from "./../../assets/images/clients/pothys.svg";
import Redgrape from "./../../assets/images/clients/redgrape.svg";
import Roche from "./../../assets/images/clients/redgrape.svg";
import Savills from "./../../assets/images/clients/savills.svg";
import Sharekhan from "./../../assets/images/clients/sharekhan.svg";
import Siemens from "./../../assets/images/clients/siemens.svg";
import Skf from "./../../assets/images/clients/skf.svg";
import Softcell from "./../../assets/images/clients/softcell.svg";
import Stahl from "./../../assets/images/clients/stahl.svg";
import Sulzer from "./../../assets/images/clients/sulzer.svg";
import Symbiance from "./../../assets/images/clients/symbiance.svg";
import UnifiedVoice from "./../../assets/images/clients/unified-voice-comm.svg";
import Valmet from "./../../assets/images/clients/valmet.svg";
import Veritas from "./../../assets/images/clients/veritas.svg";
import Vicoma from "./../../assets/images/clients/vicoma.svg";
import Yokagawa from "./../../assets/images/clients/yokogawa.svg";
import Zwick from "./../../assets/images/clients/zwick.svg";

const Clients = () => {

  const [log0Arr1, setLogoArr1] = useState([{
    direction: [],
    imgSrc: [],
  }]);
  const [log0Arr2, setLogoArr2] = useState([{
    direction: [],
    imgSrc: [],
  }]);
  const [log0Arr3, setLogoArr3] = useState([{
    direction: [],
    imgSrc: [],
  }]);
  const [log0Arr4, setLogoArr4] = useState([{
    direction: [],
    imgSrc: [],
  }]);
  const [iconLoadFlag, setIconLoadFlag] = useState(false);
 /*  const logoArr1 = [
    {
      direction: ["up", "right", "down", "left"],
      imgSrc: [Abb, Alhua, Assetplus, Bajaj],
    },
    {
      direction: ["down", "left", "up", "right"],
      imgSrc: [Balcke, Bsb, Canon, Cashkaro],
    },
    {
      direction: ["up", "right", "down", "left"],
      imgSrc: [Cisco, Cnim, Colliers, Congruent],
    },

    {
      direction: ["down", "left", "up", "right"],
      imgSrc: [Cooper, Eql, Equitas, Ewt],
    },
  ];

  const logoArr2 = [
    // Batch 2
    {
      direction: ["right", "up", "left", "down"],
      imgSrc: [Fedex, Fleet, Harting, Hindus],
    },
    {
      direction: ["left", "down", "right", "up"],
      imgSrc: [Hitachi, Iff, Imshealth, IndiaRating],
    },
    {
      direction: ["right", "up", "left", "down"],
      imgSrc: [indiqube, Ingram, Innov, Inorya],
    },
    {
      direction: ["left", "down", "right", "up"],
      imgSrc: [Iqvia, Itdata, Keyence, kns],
    },
  ]; */

  /* const logoArr3 = [
    // Batch 3
    {
      direction: ["down", "left", "up", "right"],
      imgSrc: [Ledgers, Linxon, LtIdpl, Malladi],
    },
    {
      direction: ["up", "right", "down", "left"],
      imgSrc: [MeEngineer, Medsmart, Metso, Montra],
    },
    {
      direction: ["down", "left", "up", "right"],
      imgSrc: [Nkt, Ntt, Nucleus, One],
    },
    {
      direction: ["up", "right", "down", "left"],
      imgSrc: [Pothys, Redgrape, Roche, Savills],
    },
  ];

  const logoArr4 = [
    // Batch 4
    {
      direction: ["left", "down", "right", "up"],
      imgSrc: [Sharekhan, Siemens, Skf, Softcell],
    },
    {
      direction: ["right", "up", "left", "down"],
      imgSrc: [Stahl, Sulzer, Symbiance, UnifiedVoice],
    },
    {
      direction: ["left", "down", "right", "up"],
      imgSrc: [Valmet, Veritas, Vicoma, Yokagawa],
    },
    {
      direction: ["right", "up", "left", "down"],
      imgSrc: [Zwick, Ingram, Innov, Inorya],
    },
  ]; */
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
  };
  const get_load_data = async () => {
 

    get_client_image(1);
    get_client_image(2);
    get_client_image(3);
    get_client_image(4);

  }; 

  const get_client_image = async (page) => {
    const response1 = await axios({
      method: "GET",
      url: Api.userdata.url + "get_clients?page="+page,
      headers: headers,
    });
    const json1 = await response1.data;
    if (json1["status"] == "OK") {
     if(page == 1)
     {
       setLogoArr1(json1["message"]);
     } else if (page == 2)
     {
       setLogoArr2(json1["message"]);
     }else if (page == 3)
       {
         setLogoArr3(json1["message"]);
       }else if (page == 4)
         {
           setLogoArr4(json1["message"]);
         }
    }
    const timeoutId = setTimeout(() => {
     setIconLoadFlag(true);
   }, 2000);
  }

  useEffect(() => {

    get_load_data();

  }, []);
  return (
    <div className="bg-[#F4F4F4] pt-[160px] sm:pt-[120px] pb-[80px] lg:pb-0">
      <div className="flex justify-between px-10 lg:px-[100px] pb-10">
        <h1 className="bg-[#CAFF31] inline-block px-4 py-2 rounded-[40px] text-[#626262]">
          Some of our clients
        </h1>
        <Link
          to="/clientele"
          className="hidden lg:flex gap-[30px] text-[#23262F] z-10">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="53"
            height="24"
            viewBox="0 0 53 24"
            fill="none">
            <path
              d="M1.16504 12.1426H51.6016M51.6016 12.1426L40.3735 0.914551M51.6016 12.1426L40.3735 23.3707"
              stroke="black"
              strokeLinecap="round"
            />
          </svg>
          View all Clients
        </Link>
      </div>
      <div className="px-[46px]">
        {(iconLoadFlag == true)?<>   <AnimatedLogos props={log0Arr1} />
        <AnimatedLogos props={log0Arr2} />
        <AnimatedLogos props={log0Arr3} />
        <AnimatedLogos props={log0Arr4} /></>:<div className="h-[756px]"></div>}
     
      </div>
      <Link
        to="/clientele"
        className="flex justify-center items-center lg:hidden gap-[30px] text-[#23262F] pt-10 z-10">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="28"
          height="13"
          viewBox="0 0 28 13"
          fill="none">
          <path
            d="M0.928223 6.49629H27.0718M27.0718 6.49629L21.2518 0.67627M27.0718 6.49629L21.2518 12.3163"
            stroke="black"
            strokeLinecap="round"
          />
        </svg>
        View all Clients
      </Link>
    </div>
  );
};

export default Clients;
