import React from "react";
import { Link } from "react-router-dom";

const condiUse = [
  "email or otherwise upload any content that (i) infringes any intellectual property or other proprietary rights of any party; (ii) you do not have a right to upload under any law or under contractual or fiduciary relationships; (iii) contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment; (iv) poses or creates a privacy or security risk to any person; (v) constitutes unsolicited or unauthorized advertising, promotional materials, commercial activities and/or sales, “junk mail,” “spam,” “chain letters,” “pyramid schemes,” “contests,” “sweepstakes,” or any other form of solicitation; (vi) is unlawful, harmful, threatening, abusive, harassing, tortious, excessively violent, defamatory, vulgar, obscene, pornographic, libelous, invasive of another’s privacy, hateful racially, ethnically or otherwise objectionable; or (vii) in the sole judgment of SKCL Accelerator, is objectionable or which restricts or inhibits any other person from using or enjoying the Site, or which may expose SKCL Accelerator or its users to any harm or liability of any type;",
  "interfere with or disrupt the Site or servers or networks connected to the Site, or disobey any requirements, procedures, policies or regulations of networks connected to the Site;",
  "violate any applicable local, state, national or international law, or any regulations having the force of law;",
  "impersonate any person or entity, or falsely state or otherwise misrepresent your affiliation with a person or entity;",
  "solicit personal information from anyone under the age of 18;",
  "harvest or collect email addresses or other contact information of other users from the Site by electronic or other means for the purposes of sending unsolicited emails or other unsolicited communications;",
  "advertise or offer to sell or buy any goods or services for any business purpose that is not specifically authorized;",
  "further or promote any criminal activity or enterprise or provide instructional information about illegal activities; or",
  "obtain or attempt to access or otherwise obtain any materials or information through any means not intentionally made available or provided for through the Site.",
];

// export const metadata = {
//   title: "Terms of use",
//   description: "This is a Terms of use Page",
// };

const TermsofUse = () => {
  return (
    <div>
      <div className=" bg-[#FFB780] py-[128px] rounded-tl-[40px] md:rounded-tl-[60px] rounded-tr-[40px] md:rounded-tr-[60px]">
        <h1 className="text-[40px] leading-normal font-light text-center">
          TERMS OF USE
        </h1>
      </div>

      <div className="container max-w-[1280] mx-auto py-16 px-5 md:px-20">

        <div className="pb-4">
          <p className="pb-8 text-base">
            The website (“www.skclaccelerator.com”) is maintained and run by SKCL.
          </p>
          <h1 className="pb-4 text-2xl">
            Background of the Website
          </h1>
          <p className="pb-4 text-base">The Website seeks to provide information about the following:</p>
          <ul>
            <li className="pb-4">a. Background of SKCL.</li>
            <li className="pb-4">b. Information about the services offered by SKCL.</li>
            <li className="pb-4">c. A representative list of companies incubated by SKCL.</li>
            <li className="pb-4">d. Representative list of partners with whom SKCL has entered into a tie up
            with.</li>
            <li className="pb-4">e. A few success stories &amp; testimonials.</li>
          </ul>
        </div>

        <div>
          <h1 className="pb-4 text-2xl">Disclaimer</h1>
          <p className="pb-8 text-xl">
          Nothing in this Website shall be deemed to make any reference to any offering of securities or an invitation to subscribe to or invest in any company or venture. Any person doing so will be doing at their own risk.
          </p>
        </div>

        <p className="pb-8 text-base">
          Please read these Terms and Conditions <span className="font-semibold">(“Terms of Use”)</span> carefully. Your use of the Website is subject to you first accepting and agreeing to abide by the Terms of Use contained herein, which constitutes the Agreement for the <span className="font-semibold">Purpose</span> as defined hereunder. The Acceptance of Terms of Use will be between You and SKCL (hereinafter referred as <span className="font-semibold">“SKCL”</span>) which owns the Website.
        </p>

        <p className="pb-8 text-base">
        By accessing and using this Website you have confirmed that you have read, understood and bound yourself by these <span className="font-semibold">Terms of Use.</span> These Terms of Use shall apply to the access and use of the Website. The users (hereinafter referred to as “you”, or “your”) will be subject to the rules, guidelines, policies, terms, and conditions applicable to any facilities that are provided by this <span className="font-semibold">Website</span> and all associated web pages/ sites and they shall be deemed to be incorporated into these Terms of Use and shall be considered as part and parcel of these Terms of Use.
        </p>
        
        <p className="pb-8 text-base">
        In terms of the information Technology Act, 2000 (as amended from time to time), this document is an electronic record. By accessing the Website and availing the facilities on the Website, You agree to be bound by these Terms of Use, the legal disclaimer <span className="font-semibold">(‘Legal Disclaimer’)</span> and the Privacy Policy <span className="font-semibold">(“Privacy Policy”)</span>, as posted on the Website, and as updated from time to time.
        </p>

        <p className="pb-8 text-base">
        This Agreement describes the terms governing the usage of the facilities provided to you on the Website. Clicking “I Agree” to “Terms & Conditions”, shall be considered as your electronic acceptance of this Agreement under Information Technology Act 2000. Your continued usage of the facilities from time to time would also constitute acceptance of the Terms of Use including any updation or modification thereof and you would be bound by this Agreement until this Agreement is terminated as per provisions defined herein.
        </p>

        <p className="pb-8 text-base">
        Your electronic consent, accepting these Terms of Use, represents that you have the capacity to be bound by it, or if you are acting on behalf of any person, that you have the authority to bind such person.
        </p>

        <p className="pb-8 text-base">
        You also acknowledge and agree that, unless specifically provided otherwise, these Terms of Use only apply to this Website and facilities provided on this Website.
        </p>

        <div>
          <h1 className="pb-4 text-2xl">Changes to the Terms of Use</h1>
          <p className="pb-8 text-base">
          You agree that these Terms of Use may be subject to change/modification for such reason as it may deem fit and proper, including but not limited to comply with changes in law or regulation, correct inaccuracies, omissions, errors or ambiguities, reflect changes in the process flow, scope and nature of the services, company reorganisation, market practice or customer requirements. Upon any change, the updated Terms of Use will be updated on the Website or any other means. Your continued use of the facilities on this Website constitutes acceptance of the changes and an Agreement to be bound by Terms of Use, as amended. You can review the most current version of the Terms of Use at any time, by clicking the Terms & Conditions link on the Website. <span className="font-semibold">No fee of whatsoever nature is to be charged for the use of this Website.</span>
          </p>

          <p className="pb-8 text-base font-semibold">If you do not agree with any of these Terms of Use, you may not use this Website.</p>
        </div>

        <div>
          <h1 className="pb-4 text-2xl">Authorisation to SKCL</h1>
          <p className="pb-8 text-base">
          Whenever consent has been obtained/provided by you in any mode under these Terms and Conditions, for availing information/services related to SKCL, you agree and authorise SKCL to share/transfer/transmit your personal information with third parties, in so far as required for offering of facilities through this Website and for analytical / marketing purposes /report generations and/or to offer connected facilities on the Website and may also include transfer/sharing/transmitting of sensitive personal data or information only if it is deemed necessary for the performance of facilities and to provide you with various value added and ancillary facilities/services and information, to aid you in managing your requirement in the manner agreed under these Terms and Conditions and the privacy policy. You agree to receive e-mails/sms/phone calls and such other mode as permitted under law from SKCL or its third party service providers regarding the facilities updates, information/promotional offer and/or new product announcements and such other related information.
          </p>
          <p className="pb-8 text-base">
          You also agree and authorise SKCL/ its third party service providers to contact you at the contact information provided for service related communication relating to your product or facilities offered even if your number is in National Do Not Call Registry.
          </p>
          <p className="pb-8 text-base">You have the option to withdraw the said consent in the manner specified under these Terms of Use. Please also read the Privacy Policy for more information and details as provided on the Website. The Privacy Policy may be updated from time to time. Changes will be effective upon posting of the revised Privacy Policy on the Website.
          </p>
        </div>

        <div>
          <h1 className="pb-4 text-2xl">No Warranty</h1>
          <p className="pb-8 text-base">
          This Website is provided to you on an “as is” and “where-is” basis, without any warranty. SKCL, for itself and third party providing information, Materials (defined later), facilities, or content to this Website, makes no representations or warranties, either express, implied, statutory or otherwise of merchantability, fitness for a particular purpose, or non-infringement of third party rights, with respect to the website, the information or any products or facilities to which the information refers.
          </p>
          <p className="pb-8 text-base">
          Although all efforts are made to ensure that information and content provided as part of this Website is correct at the time of inclusion on the Website, however there is no guarantee to the accuracy of the Information. This Website makes no representations or warranties as to the fairness, completeness or accuracy of Information. There is no commitment to update or correct any information that appears on the Internet or on this Website. Information is supplied upon the condition that the persons receiving the same will make their own determination as to its suitability for their purposes prior to use or in connection with the making of any decision. Any use of this Website or the information is at your own risk. Neither SKCL, nor their officers, employees or agents shall be liable for any loss, damage or expense arising out of any access to, use of, or reliance upon, this Website or the information, or any website linked to this Website.
          </p>
        </div>

        <div>
          <h1 className="pb-4 text-2xl">No Liability</h1>
          <p className="pb-8 text-base">
          SKCL will not be liable to you or any third party for any damages of any kind, including but not limited to, direct, indirect, incidental, consequential or punitive damages, arising from or connected with the Website, including but not limited to, your use of this Website or your inability to use the Website, even if SKCL has previously been advised of the possibility of such damages. Any access to information hosted on third party websites is not intended and shall not be treated as an offer to sell or the solicitation of an offer to buy any product/offering of third parties.
          </p>
          <p className="pb-8 text-base">
          SKCL shall have absolutely no liability in connection with the information and Material posted on this Website including any liability for damage to your computer hardware, data, information, materials, or business resulting from the information and/or Material or the lack of information and/or Material available on the Website.
          </p>
          <p className="pb-8 text-base">
          In no event shall SKCL be liable for any for any special, incidental, consequential, exemplary or punitive damages for any loss, arising out of or in connection with (a) the use, misuse or the inability to use this Website; (b) unauthorized access to or alteration of your transmissions or data; (c) statements or conduct of any third party on the site; or (d) any other matter whatever nature relating to this Website.
          </p>
          <p className="pb-8 text-base">
          Further, SKCL cannot always foresee or anticipate technical or other difficulties. The facilities may contain errors, bugs, or other problems. These difficulties may result in loss of data, personalization settings or other facilities interruptions. The Website does not assume responsibility for the timeliness, deletion, mis-delivery, or failure to store any user data, communications, or personalization settings.
          </p>
          <p className="pb-8 text-base">
          SKCL and the providers of information shall not be liable, at any time, for any failure of performance, error, omission, interruption, deletion, defect, delay in operation or transmission, computer virus, communications line failure, theft or destruction or unauthorized access to, alteration of, or use of information contained at this Website.
          </p>
          <p className="pb-8 text-base">
          Certain information and material on the Website may be made available through a password protected section on this Website. Each password is intended to be confidential and used only by the individual or entity to whom it is issued. Do not disclose your password to any unauthorized person. Please note that information provided through a password protected sections may be of a confidential and are subject to limitations on disclosure and use.
          </p>
          <p className="pb-8 text-base">
          Nothing on this Website is intended to constitute (i) an offer, or solicitation of an offer, to purchase or sell any security, other asset or service, (ii) investment advice or an offer to provide such advice, or (iii) a basis for making any investment decision; or (iv) a promise/undertaking to invest or provide financing. Except as expressly stated by a duly authorised representative in writing, neither this Website nor any of the information provided herein make any effort to present a comprehensive or balanced description of investment activities.
          </p>
          <p className="pb-8 text-base">
          All text, photos, graphics, logos, content and other information on this Website are protected by applicable copyright, trademark and other applicable laws. 
          </p>
          <p className="pb-8 text-base">
          We reserve all rights with respect to the design and content of this Website. You must not misappropriate the design or content of this Website and You must not alter or deface such design or content in any way. Nothing on this Website grants any license with respect to such design or content, except that you may download and use the information solely for your own personal requirement, and not for any commercial activity.
          </p>
        </div>

      </div>
    </div>
  );
};

export default TermsofUse;
