// // "use client";
// import Head from "next/head";
// import Image from "next/image";
import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Marquee from "../components/about/marquee";
import Marquee2 from "../components/about/marquee2";
import Slider from "../components/about/slider";
import WhatDottedImg from "./../assets/images/about/what-dotted-bg.png";
import SlideUp from "../components/slideUp";
import aboutVideo from "./../assets/videos/Aboutus.mp4";
import acceVideo from "./../assets/videos/acce.mp4";
import acceleratorVideo2 from "./../assets/videos/accelerator-program.mp4";
import aboutBanner from "./../assets/videos/about-banner.mp4";
import DottedRight from "./../assets/images/about/dotted-right.png";
import DottedLeft from "./../assets/images/about/dotted-left.png";

const acceleratordata = {
  aboutProgram: [
    {
      title: "In simple words what is an accelerator program?",
      content: "It's like an intensive summer school for young business mind",
    },
    {
      title: "When do we host it?",
      content:
        "Our accelerator hosts two 3-month programs - one from February to April and one from July to September.",
    },
  ],

  whoPoints: [
    {
      num: "01",
      content: "For Entrepreneurs with a MVP (Minimum Viable Product)",
    },
    {
      num: "02",
      content: "For businesses that want to scale.",
    },
    {
      num: "03",
      content: "For companies that need advice and domain expertise.",
    },
  ],
};

const accordianData = [
  {
    title: "The Goals",
    content:
      "The overall goal is to help startups catapult to the next phase of growth and revenue by the end of the intensive program. For most startups this translates into two things: better product with more customers and more fundraising options. We help founder's unleash their true potential, the secret push they never knew they needed to reach the greatest heights.",
  },
  {
    title: "Funding",
    content:
      "SKCL Accelerator invests 20,00,000 INR in every company on standard terms on a post money safe in return for 3% of your company. An additional 2% equity for a 12 month rent free tenure for 3 seats. When we succeed in getting you the funding you require,2% of the funding amount needs to be reimbursed to the accelerator fund to help with supporting other startups.Think of it as paying it forward.",
  },
  {
    title: "Groups",
    content:
      "Startups are divided into 2-3 groups based on industry domain which are led by group partners who advise the founders on a one-on-one basis or group advisory sessions during office hours.",
  },
  {
    title: "Office Hours",
    content:
      "Much of the most productive work happens during office hours.Group partners host group office hours every two weeks and one-on-one office hours as often as founders want.",
  },
  {
    title: "Alumni/Corporate Talks",
    content:
      "With over 90+ global corporate clients, we invite an eminent person every week to speak to our cohort founders.",
  },
  {
    title: "Public Launches",
    content:
      "Once a startup has built something that needs to launch to a larger audience, we help founders figure out how to present it to their users and clientele.We assist with creating online and offline presence for the founders and the brand.",
  },
  {
    title: "Demo Day",
    content:
      "On Demo Day, the founders pitch their companies to an audience of renowned HNI's,industry experts and investors. In the weeks that follow we stay connected to our startups as they negotiate the fundraising maze and help them transition smoothly.",
  },
  {
    title: "Beyond the batch",
    content:
      "SKCL Accelerator doesn't stop after the 3 month program ends.Here are some of the resources available to our graduating alumni as their companies grow.",
  },
  {
    title: "Advice",
    content:
      " Office hours doesn't stop after the program. We have office hours all year round and startups from all previous cycles can book a slot whenever they want",
  },
  {
    title: "Community",
    content:
      "At SKCL Accelerator we believe in the strong sense of community.If they ever come across a challenge they need help with,they not only have partners at their disposal but also domain experts they can count on.",
  },
  {
    title: "Alumni demo day",
    content:
      "Active founders get an early look at the SKCL cohort companies in each batch at Alumni Demo Day.",
  },
];

const About = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <>
      {/* <Head>
        <title>About Page</title>
        <meta name="description" content="This is a About page" />
        <meta name="keywords" content="about, nextjs, react" />
      </Head> */}
      <div>
        {/* Hero Section */}
        <div className="relative overflow-hidden flex items-center justify-center h-[450px] md:h-[510px] bg-[#FFB780] rounded-tl-[40px] md:rounded-tl-[80px] rounded-tr-[40px] md:rounded-tr-[80px] px-5 z-10">
          <video
            autoPlay
            muted
            loop
            playsInline
            controlsList="nodownload"
            controls={false}
            preload="none"
            className="absolute h-full w-full z-[-1] scale-[2] md:scale-150">
            <source src={aboutBanner} type="video/mp4" playsInline />
          </video>
          <SlideUp custom={0.5}>
            <h1 className="text-3xl lg:text-[48px] leading-normal text-center font-light lg:w-[670px]">
              In constant search of <br className="hidden md:block" />
              "EXCELLENCE”
            </h1>
          </SlideUp>
        </div>

        {/* About Section */}
        <div className="container max-w-[1440px] mx-auto relative px-5 lg:pl-[100px] pt-20 lg:pt-[160px] pb-[320px] sm:pb-10 lg:pb-[60px]">
          <video
            autoPlay
            muted
            loop
            playsInline
            controlsList="nodownload"
            controls={false}
            preload="none"
            className="absolute bottom-0 right-0 md:top-20 lg:top-[180px] xl:top-[140px] md:w-[400px] lg:w-[400px] xl:w-[600px] z-[-1]">
            <source src={aboutVideo} type="video/mp4" />
          </video>
          <SlideUp custom={0.6}>
            <p className="mb-10 bg-[#CAFF31] text-[#626262] rounded-[40px] inline-block px-4 py-2">
              About
            </p>
          </SlideUp>
          <SlideUp custom={0.7}>
            <h1 className="text-2xl lg:text-4xl leading-[36px] lg:leading-[50px] font-light w-[303px] lg:w-[560px] xl:w-[760px] pb-6">
              WE ARE SKCL. A group of companies committed to deep work,
              integrity and excellence.
            </h1>
          </SlideUp>
          <SlideUp custom={0.8}>
            <p className="md:w-[400px] lg:w-[480px] xl:w-[562px]">
              SRI KAUSALYA CONSTRUCTIONS LTD (SKCL) founded in 2003,is a leading
              construction company in Chennai. Over 20 years SKCL has delivered
              over a million sft in Chennai and has turned around the meaning of
              Grade A buildings in the city which are executed with integrity,
              precision & quality.
              <br /> <br />
            </p>
            <div className="md:w-[400px] lg:w-[480px] xl:w-[562px]">
              <p>
                Clients include: <br />
              </p>
              <p className="border-t-[1px] pt-2 border-[#c9c2c2]">
                CANON, CISCO, FEDEX, COCA-COLA, ABB, COLLIERS{" "}
                <br className="hidden md:block" />
                INTERNATIONAL,HITACHI AMONGST MANY.
              </p>
            </div>
          </SlideUp>
        </div>

        {/* Marquess section */}
        <div className="relative overflow-hidden py-20 bg-[#ECFFB5] z-10">
          <img
            src={DottedRight}
            alt="dotted-bg"
            className="absolute top-0 right-0 z-[-1]"
          />
          <img
            src={DottedLeft}
            alt="dotted-bg"
            className="absolute bottom-0 left-0 z-[-1]"
          />
          <Marquee />
          <SlideUp custom={0.5}>
            <p className="text-[20px] leading-[30px] lg:w-[560px] px-5 lg:pl-[100px] pt-10 pb-[60px]">
              We are on a Mission to provide a launch pad for entrepreneurs with
              “Simple ideas & Big dreams”,the ones with zeal & grit to persevere
              ,the ones with the hunger to win by providing them with unparalled
              support,infra,resources & oppurtunities,where “they don't just
              Survive but Thrive.
            </p>
          </SlideUp>

          <Marquee2 />
          <SlideUp custom={0.5}>
            <div className="flex justify-end w-full md:pr-[100px]">
              <p className="text-[20px] leading-[30px] right-0 lg:right-[100px] lg:w-[460px] px-5 pt-10">
                Our Vision is to invest in industry disruptors, path brakers,
                innovators, creative leaders and the kind of people who push the
                human race & the community forward.
              </p>
            </div>
          </SlideUp>
        </div>

        {/* What we do section */}
        <div className="relative">
          <img
            src={WhatDottedImg}
            alt="dotted-bg"
            className="absolute bottom-0 w-full bg-no-repeat"
          />

          <div className="container max-w-[1440px] mx-auto relative grid items-end grid-cols-1 lg:grid-cols-2 overflow-hidden px-5  lg:pr-28">
            <video
              autoPlay
              muted
              loop
              playsInline
              controlsList="nodownload"
              controls={false}
              preload="none"
              className="hidden md:block w-full h-auto md:h-[1300px] object-contain md:mb-[-190px] lg:mb-[-280px] mix-blend-multiply">
              <source src={acceVideo} type="video/mp4" playsInline />
            </video>

            <div className="pb-[100px] pt-16 md:pt-0">
              <div className="pb-[72px]">
                <SlideUp custom={0.4}>
                  <p className="mb-10 bg-[#CAFF31] text-[#626262] rounded-[40px] inline-block px-4 py-2">
                    What we do?
                  </p>
                </SlideUp>
                <SlideUp custom={0.5}>
                  <h1 className="text-[28px] leading-normal lg:text-4xl lg:leading-[50px] font-light text-[#626262] pb-4">
                    We don't just want you to survive, We want to see you
                    thrive.
                  </h1>
                </SlideUp>
                <SlideUp custom={0.6}>
                  <p className="font-light text-base w-auto xl:w-[600px]">
                    Whether you're building a business or scaling up to meet
                    customer requirements, we help you flourish by giving you
                    space, connecting you with the right people for mentorship,
                    scaling up & being at the right place at the right time.{" "}
                    <br /> <br />
                    Because if businesses succeed, the economy rises, GDP
                    increases, the standard of living changes. It takes
                    communities forward. <br />
                    <span className="text-lg font-semibold">
                      "Countries thrive, not just survive."
                    </span>
                  </p>
                </SlideUp>
              </div>
              <div>
                <SlideUp custom={0.4}>
                  <p className="mb-10 bg-[#CAFF31] text-[#626262] rounded-[40px] inline-block px-4 py-2">
                    Why us?
                  </p>
                </SlideUp>
                <SlideUp custom={0.5}>
                  <h1 className="text-[28px] leading-normal lg:text-4xl lg:leading-[50px] font-light text-[#626262] pb-4">
                    Fuelling & Empowering the next big global dominance.
                  </h1>
                </SlideUp>
                <SlideUp custom={0.6}>
                  <p className="font-light text-base w-auto xl:w-[570px]">
                    Startups are solving the world's untold challenges with
                    technology, innovation & perseverance. We are privileged to
                    fuel such risk takers, go-getters, the ones that push the
                    human race forward on their entrepreneurial journey. Our aim
                    is to provide a launch pad to make them the forefront of
                    global innovation.
                  </p>
                </SlideUp>
              </div>
            </div>
          </div>
        </div>

        {/* Accelerator Program */}
        {/* <div className="relative overflow-hidden bg-[#F4F4F4] h-auto xl:h-[1300px]">
          <video
            autoPlay
            muted
            loop
            playsInline
            controlsList="nodownload"
            controls={false}
            preload="none"
            className="hidden lg:block absolute bottom-[-100px] left-[-120px] w-[560px] mix-blend-multiply">
            <source src={acceleratorVideo2} type="video/mp4" playsInline />
          </video>
          <div className="container mx-auto max-w-[1440px] grid grid-cols-1 lg:grid-cols-2 gap-14 py-16 md:py-[100px] px-5 lg:px-[60px]">
            <div>
              <SlideUp custom={0.4}>
                <p className="mb-10 bg-[#CAFF31] text-[#626262] rounded-[40px] inline-block px-4 py-2">
                  Accelerator program
                </p>
              </SlideUp>
              <div>
                <SlideUp custom={0.5}>
                  <h1 className="text-[28px] leading-normal lg:text-4xl font-light text-[#626262] pb-4">
                    The SKCL Accelerator Program
                  </h1>
                  {acceleratordata.aboutProgram.map(({ title, content }, i) => (
                    <div key={i} className="pb-8">
                      <h3 className="text-2xl font-light pb-[4px]">{title}</h3>
                      <p className="text-base">{content}</p>
                    </div>
                  ))}
                </SlideUp>
              </div>
              <SlideUp custom={0.6}>
                <div className="pt-8">
                  <h1 className="pb-6 text-2xl font-light">Who is it for?</h1>
                  {acceleratordata.whoPoints.map(({ num, content }, i) => (
                    <div key={i} className="pb-7">
                      <h5 className="text-base text-[#777E90] pb-[6px]">
                        {num}
                      </h5>
                      <p className="text-[20px] leading-normal">{content}</p>
                    </div>
                  ))}
                </div>
              </SlideUp>
            </div>

            <SlideUp custom={0.5}>
              <div className="p-[20px] lg:p-[52px] bg-[#DFFF84] rounded-[38px]">
                {accordianData.map(({ title, content }, i) => (
                  <div
                    className="border-b-[0.5px] border-[#C1C1C1] pb-5"
                    key={i}>
                    <div
                      className="flex items-center justify-between hover:cursor-pointer"
                      onClick={() => handleToggle(i)}>
                      <h1 className="flex flex-between text-[20px] py-4">
                        {title}
                      </h1>
                      <span>
                        {activeIndex === i ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none">
                            <circle
                              cx="12.0864"
                              cy="12.1475"
                              r="11.4032"
                              transform="rotate(90 12.0864 12.1475)"
                              stroke="#23262F"
                              strokeWidth="0.8"
                            />
                            <path
                              d="M7.63867 14.5488L12.4417 9.74581L17.2447 14.5488"
                              stroke="#23262F"
                              strokeWidth="0.8"
                              strokeLinecap="round"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none">
                            <circle
                              cx="12.0864"
                              cy="12.1475"
                              r="11.4032"
                              transform="rotate(90 12.0864 12.1475)"
                              stroke="#23262F"
                              strokeWidth="0.8"
                            />
                            <path
                              d="M17.2446 9.74585L12.4416 14.5489L7.6386 9.74585"
                              stroke="#23262F"
                              strokeWidth="0.8"
                              strokeLinecap="round"
                            />
                          </svg>
                        )}
                      </span>
                    </div>
                    <AnimatePresence initial={false}>
                      {activeIndex === i && (
                        <motion.p
                          initial={{ height: 0, opacity: 0 }}
                          animate={{ height: "auto", opacity: 1 }}
                          exit={{ height: 0, opacity: 0 }}
                          transition={{ duration: 0.4 }}
                          className="text-base w-auto xl:w-[465px] font-light overflow-hidden">
                          {content}
                        </motion.p>
                      )}
                    </AnimatePresence>
                  </div>
                ))}
              </div>
            </SlideUp>
          </div>
        </div> */}

        {/* Slider Section */}
        <div className="relative px-5 lg:px-[60px] pt-20 container max-w-[1440px] mx-auto">
          <div className="flex flex-col-reverse items-start justify-between w-full gap-8 lg:gap-0 lg:flex-row lg:items-end pb-[88px]">
            <SlideUp custom={0.5}>
              <h1 className="text-[28px] leading-[42px] lg:text-4xl lg:leading-[52px] font-light text-[#626262] w-[340px] md:w-[540px] lg:w-[742px]">
                We support your business growth by providing space, connecting
                you with mentors, and helping you scale effectively.
              </h1>
            </SlideUp>
            <SlideUp custom={0.6}>
              <p className="bg-[#CAFF31] text-[#626262] rounded-[40px] inline-block px-4 py-2 whitespace-nowrap">
                Our Guiding Values
              </p>
            </SlideUp>
          </div>

          {/* Slider */}
          <SlideUp custom={0.6}>
            <Slider />
          </SlideUp>
        </div>
      </div>
    </>
  );
};

export default About;
