// "use client";
import { useState, useEffect, React, useRef } from "react";
import axios from "axios";
import Api from "../../package.json";
// import Head from "next/head";
// import Image from "next/image";
import Investors from "./../assets/images/partners/Investors.png";
import Accelerators from "./../assets/images/partners/Accelerators.png";
import Mentors from "./../assets/images/partners/Mentors.png";
import BajajImg from "./../assets/images/clients/bajaj.svg";
import SlideUp from "../components/slideUp";
import Herobanner from "./../assets/videos/partners-banner.mp4";
import PersonPlaceholder from "./../assets/images/partners/person-placeholder.png";

const Partners = () => {
  const [categoryName, setCategoryName] = useState([]);
  const [catDetails, setCatDetails] = useState([]);
  const categories = [
    {
      investorName: "Investors",
      imageUrl: Investors,
      url: "investors",
    },
    {
      investorName: "Accelerators",
      imageUrl: Accelerators,
      url: "accelerators",
    },
    {
      investorName: "Mentors",
      imageUrl: Mentors,
      url: "mentors",
    },
  ];

  const accelerators = [
    {
      logoUrl: PersonPlaceholder,
      companyName: "Bajaj",
      desc: "Lorem ipsum dolor sit amet consectetur. Gravida id et congue eget mollis a. Vehicula aliquet diam penatibus ut tincidunt volutpat",
    },
    {
      logoUrl: PersonPlaceholder,
      companyName: "Bajaj",
      desc: "Lorem ipsum dolor sit amet consectetur. Gravida id et congue eget mollis a. Vehicula aliquet diam penatibus ut tincidunt volutpat",
    },
    {
      logoUrl: PersonPlaceholder,
      companyName: "Bajaj",
      desc: "Lorem ipsum dolor sit amet consectetur. Gravida id et congue eget mollis a. Vehicula aliquet diam penatibus ut tincidunt volutpat",
    },
  ];

  const investors = [
    {
      logoUrl: PersonPlaceholder,
      companyName: "Bajaj",
      desc: "Lorem ipsum dolor sit amet consectetur. Gravida id et congue eget mollis a. Vehicula aliquet diam penatibus ut tincidunt volutpat",
    },
    {
      logoUrl: PersonPlaceholder,
      companyName: "Bajaj",
      desc: "Lorem ipsum dolor sit amet consectetur. Gravida id et congue eget mollis a. Vehicula aliquet diam penatibus ut tincidunt volutpat",
    },
    {
      logoUrl: PersonPlaceholder,
      companyName: "Bajaj",
      desc: "Lorem ipsum dolor sit amet consectetur. Gravida id et congue eget mollis a. Vehicula aliquet diam penatibus ut tincidunt volutpat",
    },
  ];

  const mentors = [
    {
      logoUrl: PersonPlaceholder,
      companyName: "Bajaj",
      desc: "Lorem ipsum dolor sit amet consectetur. Gravida id et congue eget mollis a. Vehicula aliquet diam penatibus ut tincidunt volutpat",
    },
    {
      logoUrl: PersonPlaceholder,
      companyName: "Bajaj",
      desc: "Lorem ipsum dolor sit amet consectetur. Gravida id et congue eget mollis a. Vehicula aliquet diam penatibus ut tincidunt volutpat",
    },
    {
      logoUrl: PersonPlaceholder,
      companyName: "Bajaj",
      desc: "Lorem ipsum dolor sit amet consectetur. Gravida id et congue eget mollis a. Vehicula aliquet diam penatibus ut tincidunt volutpat",
    },
  ];

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
  };

  const get_load_data = async () => {
    const response = await axios({
      method: "GET",
      url: Api.userdata.url + "get_category_name?parent=partners&hj=sd",

      headers: headers,
    });
    const json = await response.data;
    if (json["status"] == "OK") {
      setCategoryName(json["result"]);
    }
    const response1 = await axios({
      method: "GET",
      url: Api.userdata.url + "get_partners?sdf",

      headers: headers,
    });
    const json1 = await response1.data;
    if (json1["status"] == "OK") {
      setCatDetails(json1["partners_details"]);
    }
  };

  useEffect(() => {
    get_load_data();
  }, []);
  return (
    <>
      {/* <Head>
        <title>Partners Page</title>
        <meta name="description" content="This is a Partners page" />
        <meta name="keywords" content="partners, nextjs, react" />
      </Head> */}

      {/* Hero Section */}

      <div className="relative overflow-hidden flex flex-col items-center justify-center gap-3 bg-[#7AC7FD] rounded-tl-[40px] rounded-tr-[40px] h-[450px] md:h-[510px] z-10">
        <SlideUp custom={0.5}>
          <p className="text-base font-medium text-center ">PARTNERS</p>
          <h1 className="text-3xl md:text-[40px] md:leading-normal font-light text-center">
            Accelerate your startup
          </h1>
        </SlideUp>
        <video
          autoPlay
          muted
          loop
          playsInline
          controlsList="nodownload"
          controls={false}
          preload="none"
          className="absolute h-full w-full z-[-1] scale-[3] md:scale-150 rotate-[-20deg] mr-[100px]">
          <source src={Herobanner} type="video/mp4" playsInline />
        </video>
      </div>

      {/* Categories Section */}
      <div className="relative grid grid-cols-1 md:grid-cols-3 container max-w-[1440px] mx-auto">
        {categoryName.map((category_name, index) => {
          {
            var img_src = "";
            if (category_name.name == "Investors") {
              img_src = Investors;
            } else if (category_name.name == "Incubators/Accelerators") {
              img_src = Accelerators;
            } else if (category_name.name == "Mentors") {
              img_src = Mentors;
            } else {
              img_src = "";
            }
          }
          return (
            <div className="relative flex flex-col justify-end" key={index}>
              <img src={img_src} alt="cate-img" />
              <div className="absolute px-[30px] text-white pb-7">
                <h2 className="text-2xl md:text-[32px] leading-5 pb-5">
                  {category_name.name}
                </h2>
                <button
                  onClick={() => scrollToSection(category_name.slug)}
                  className="flex items-center gap-[26px] font-[14px] leading-normal">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="44"
                    height="21"
                    viewBox="0 0 44 21"
                    fill="none">
                    <path
                      d="M1.12744 10.5052H43.3391M43.3391 10.5052L33.9421 1.10815M43.3391 10.5052L33.9421 19.9023"
                      stroke="#F4F5F6"
                      strokeWidth="0.836926"
                      strokeLinecap="round"
                    />
                  </svg>
                  Know more
                </button>
              </div>
            </div>
          );
        })}
      </div>
      {/* Category Breif section */}
      <div className="relative flex flex-col gap-[120px] px-5 lg:px-[60px] pt-20 pb-20 container max-w-[1440px] mx-auto">
        {categoryName &&
          categoryName.map((category, index) => {
            {
            }
            return (
              <div id={category.slug}>
                <p className="mb-8 bg-[#CAFF31] rounded-[40px] inline-block px-4 py-2">
                  {category.name}
                </p>

                <div
                  className="grid grid-cols-2 gap-14 md:grid-cols-3 lg:grid-cols-3 md:gap-14"
                  key={"index"}>
                  {catDetails[category.name] &&
                    catDetails[category.name].map((sub_partner) => (
                      <>
                        {category.name == "Mentors" ? (
                          <>
                            <div
                              className="flex flex-col items-start gap-5 md:gap-6"
                              key={"index"}>
                              {sub_partner.featured_image ? (
                                <img
                                  src={sub_partner.featured_image}
                                  className="h-auto xl:h-[420px] object-contain"
                                  alt="client-img"
                                />
                              ) : (
                                ""
                              )}

                              {sub_partner.company_logo != "" ? (
                                <img
                                  src={sub_partner.company_logo}
                                  alt="client-logo"
                                  className="rounded-lg"
                                />
                              ) : (
                                ""
                              )}

                              <div>
                                <h3 className="pb-2 text-[18px] md:text-xl">
                                  {sub_partner.person_name}
                                </h3>
                                <p className="text-sm md:text-base md:mt-[-10px]">
                                  {sub_partner.designation}
                                </p>
                                <p className="xl:w-[368px]">
                                  {sub_partner.description}
                                </p>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              className="flex flex-col items-start gap-5 md:gap-8"
                              key={"index"}>
                              {sub_partner.company_logo != "" ? (
                                <img
                                  src={sub_partner.company_logo}
                                  alt="client-img"
                                  className="rounded-lg"
                                />
                              ) : (
                                ""
                              )}

                              {/* <h3>{sub_partner.logo_text}</h3> */}

                              {/*  <p className="xl:w-[368px]">
                                {sub_partner.description}
                              </p> */}
                            </div>
                          </>
                        )}
                      </>
                    ))}
                </div>

                {/*  <div
                  className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[64px] md:gap-5"
                  key={index}>
                  {partnersdfgdfg[partners].map((sub_partner, index) => (
                    <>
                      <div className="flex flex-col items-start gap-8" key={index}>
                        <img src={logoUrl} alt="client-logo" />
                        <h3>{sub_partner.company_name}</h3>
                        <p className="xl:w-[368px]">{sub_partner.description}</p>
                      </div>
                    </>
                  ))}
              </div> */}

                {/*   <SingleCategory props={accelerators} /> */}
              </div>
            );
          })}

        {/*   <div id="investors">
          <p className="mb-8 bg-[#CAFF31] rounded-[40px] inline-block px-4 py-2">
            Investors
          </p>
          <SingleCategory props={investors} />
        </div>
        <div id="mentors">
          <p className="mb-8 bg-[#CAFF31] rounded-[40px] inline-block px-4 py-2">
            Mentors
          </p>
          <SingleCategory props={mentors} />
        </div> */}
      </div>
    </>
  );
};

export default Partners;

const SingleCategory = ({ props }, index) => {
  return (
    <div
      className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[64px] md:gap-5"
      key={index}>
      {props.map(({ logoUrl, companyName, desc }, index) => (
        <>
          <div className="flex flex-col items-start gap-8" key={index}>
            <img src={logoUrl} alt="client-logo" />
            <h3>{companyName}</h3>
            <p className="xl:w-[368px]">{desc}</p>
          </div>
        </>
      ))}
    </div>
  );
};
