// // "use client";
// import Head from "next/head";
import React, { useState, useEffect } from "react";
// import Link from "next/link";
// import Image from "next/image";
import BlogImg from "./../assets/images/common/blog-img.png";
import SlideUp from "../components/slideUp";
import { Link } from "react-router-dom";
import axios from "axios";
import Api from "../../package.json";

// export const metadata = {
//   title: "News | SKCL",
//   description: "Created By FPF",
// };

const blogs = [
  {
    blogTitle: "Lorem ipsum dolor sit amet conetiam quam in blandit massa.",
    category: "Success stories",
    imgUrl: BlogImg,
    blogUrl: "/blog-template",
  },
  {
    blogTitle: "Lorem ipsum dolor sit amet conetiam quam in blandit massa.",
    category: "News",
    imgUrl: BlogImg,
    blogUrl: "/blog-template",
  },
  {
    blogTitle: "Lorem ipsum dolor sit amet conetiam quam in blandit massa.",
    category: "Success stories",
    imgUrl: BlogImg,
    blogUrl: "/blog-template",
  },
];

const Blog = () => {
  const [featuredNews, setFeaturedNews] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [categories, setCategories] = useState(["All"]);
  const [newsDetails, setNewsDetails] = useState([]);

  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
  };

 const get_load_data = async () => {

        const response2 = await axios({
          method: "GET",
          url: Api.userdata.url + "get_featured_news",
          headers: headers,
        });
        const json2 = await response2.data;
        if (json2["status"] == "OK") {
         
          setFeaturedNews(json2["message"]);
        } 


    
    const response = await axios({
      method: "GET",
      url: Api.userdata.url + "get_category_name?parent=News",
      
      headers: headers,
    });
    const json = await response.data;
    if (json["status"] == "OK") {
      const uniqueCategories = [
        "All",
        ...new Set(json["result"].map((cat) => cat.name)),
      ];
      setCategories(uniqueCategories);
    } 

    news_details('');

  }; 

  const news_details = async (url) => {
 
     if(url == 'All')
     {
      url = '';
     }
    const response1 = await axios({
      method: "GET",
      url: Api.userdata.url + "get_news?cat="+url,
      
      headers: headers,
    });
    const json1 = await response1.data;
    if (json1["status"] == "OK") { 
      setNewsDetails(json1["news"]);

    }
    
  }

  useEffect(() => {
    // Extract unique categories from the blogs
    get_load_data();
  /*    const uniqueCategories = [
      "All",
      ...new Set(blogs.map((blog) => blog.category)),
    ];
    setCategories(uniqueCategories); */
  }, [blogs]);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    news_details(category);
    
  };

 /*  const filteredBlogs =
    selectedCategory === "All"
      ? blogs
      : blogs.filter((blog) => blog.category === selectedCategory); */

  return (
    <>
      {/* <Head>
        <title>Blogs Page</title>
        <meta name="description" content="This is a Blogs page" />
        <meta name="keywords" content="blogs, nextjs, react" />
      </Head> */}

      <div>
        {/* Hero section */}
         {featuredNews != '' &&  <div className="relative flex flex-col md:flex-row-reverse md:gap-10 xl:gap-36 items-start md:justify-center px-5 bg-[#A6F5C5] py-20 ">
          <SlideUp custom={0.6}>
            <img
              src={featuredNews.featured_image}
              alt="blog-img"
              className="md:mb-[-120px] rounded-md w-auto lg:w-[600px]"
            />
          </SlideUp>
          <div>
            <SlideUp custom={0.4}>
              <p className="my-5 bg-[#CAFF31] text-[#626262] rounded-[40px] inline-block px-4 py-2">
              {featuredNews.cat}
              </p>
            </SlideUp>
            <SlideUp custom={0.5}>
              <h1 className="text-2xl xl:text-[32px] leading-[36px] xl:leading-[42px] pb-3 lg:w-[420px] xl:w-[530px]">
              {featuredNews.short_description}
              </h1>
            </SlideUp>

           {/*  <SlideUp custom={0.6}>
              <p className="pb-8 text-base">
                Lorem ipsum dolor sit amet consectetur. etiam quam in blandit
              </p>
            </SlideUp> */}

            <SlideUp custom={0.6}>
              <Link to={'/blog-template'} state={{
                    id: featuredNews.ID 
                  }} className="flex items-center gap-5">
                <svg
                  width="43"
                  height="44"
                  viewBox="0 0 43 44"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <rect
                    x="11.0587"
                    y="10.812"
                    width="26.5547"
                    height="22.6992"
                    fill="black"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21.7958 43.3743C33.5113 43.3743 43.0085 33.877 43.0085 22.1615C43.0085 10.446 33.5113 0.94873 21.7958 0.94873C10.0803 0.94873 0.583008 10.446 0.583008 22.1615C0.583008 33.877 10.0803 43.3743 21.7958 43.3743ZM23.0015 10.9012C22.412 10.319 21.4623 10.325 20.8802 10.9144C20.298 11.5038 20.3039 12.4535 20.8934 13.0356L28.3357 20.3861H12.1538C11.3254 20.3861 10.6538 21.0576 10.6538 21.8861C10.6538 22.7145 11.3254 23.3861 12.1538 23.3861H28.3357L20.8934 30.7365C20.3039 31.3186 20.298 32.2684 20.8802 32.8578C21.4623 33.4472 22.412 33.4531 23.0015 32.871L33.0432 22.9533L34.1238 21.8861L33.0432 20.8188L23.0015 10.9012Z"
                    fill="#CAFF31"
                  />
                </svg>
                Read Article
              </Link>
            </SlideUp>
          </div>
        </div>}
         
  
        

        {/* Blog list section */}
        <SlideUp custom={0.6}>
          <div className="relative container max-w-[1440px] mx-auto px-5 xl:px-[60px] pt-16 lg:pt-28 pb-10 md:pb-20 border-b-[1px]">
            <div className="flex justify-center gap-5 lg:gap-20 pb-10 lg:pb-[64px]">
              {categories.map((category) => (
                <p
                  key={category}
                  onClick={() => handleCategoryClick(category)}
                  className={`cursor-pointer ${
                    selectedCategory === category ? "font-medium" : ""
                  }`}>
                  {category}
                </p>
              ))}
            </div>

            <div className="grid grid-cols-1 gap-16 sm:grid-cols-2 sm:gap-10 xl:grid-cols-3 xl:gap-8">

            {newsDetails && newsDetails.map(
                (newsDetail, index) => (
                  <div key={index}>
                    <img
                      src={newsDetail.featured_image}
                      alt="blog-thumb"
                      className="rounded-md xl:w-[416px]"
                    />
                    <p className="my-5 bg-[#CAFF31] text-sm text-[#626262] rounded-[40px] inline-block px-4 py-2">
                      {newsDetail.cat}
                    </p>
                    <h1 className="pb-6 text-xl xl:text-2xl">{newsDetail.short_description}</h1>
                    <Link to={'/blog-template'} state={{
                    id: newsDetail.ID 
                  }} className="flex items-center gap-6">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="43"
                        height="20"
                        viewBox="0 0 43 20"
                        fill="none">
                        <path
                          d="M0.380859 9.85695H41.7675M41.7675 9.85695L32.5541 0.643555M41.7675 9.85695L32.5541 19.0704"
                          stroke="black"
                          strokeWidth="0.820568"
                          strokeLinecap="round"
                        />
                      </svg>
                      Know more
                    </Link>
                  </div>
                )
              )}

              {/* {filteredBlogs.map(
                ({ blogTitle, imgUrl, blogUrl, category }, index) => (
                  <div key={index}>
                    <img
                      src={imgUrl}
                      alt="blog-thumb"
                      className="rounded-md xl:w-[416px]"
                    />
                    <p className="my-5 bg-[#CAFF31] text-sm text-[#626262] rounded-[40px] inline-block px-4 py-2">
                      {category}
                    </p>
                    <h1 className="pb-6 text-xl xl:text-2xl">{blogTitle}</h1>
                    <Link to={blogUrl} className="flex items-center gap-6">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="43"
                        height="20"
                        viewBox="0 0 43 20"
                        fill="none">
                        <path
                          d="M0.380859 9.85695H41.7675M41.7675 9.85695L32.5541 0.643555M41.7675 9.85695L32.5541 19.0704"
                          stroke="black"
                          strokeWidth="0.820568"
                          strokeLinecap="round"
                        />
                      </svg>
                      Know more
                    </Link>
                  </div>
                )
              )} */}
            </div>
          </div>
        </SlideUp>
      </div>
    </>
  );
};

export default Blog;
