// "use client";
import React from "react";
import { Link } from "react-router-dom";
// import Image from "next/image";
import Logo from "./../assets/images/common/skcl-logo.png";
import FooterBg from "./../assets/images/common/footer-bg.png";
import FooterDottedBg from "./../assets/images/common/footer-dotted-bg.png";
import FooterDottedBgSm from "./../assets/images/common/footer-dotted-bg-sm.png";
import FooterVideo from "./../assets/videos/Footer.mp4";

const Footer = () => {
  const socialData = {
    title: "Socials",
    links: [
      {
        socialName: "LinkedIn",
        url: "#",
      },
      {
        socialName: "Facebook",
        url: "#",
      },
      {
        socialName: "Instagram",
        url: "#",
      },
      {
        socialName: "X",
        url: "#",
      },
    ],
  };

  const companyData = {
    title: "Company",
    links: [
      {
        option: "Partners",
        url: "/partners",
      },
      {
        option: "Clients",
        url: "/clientele",
      },
      {
        option: "Apply now",
        url: "/apply-now",
      },
    ],
  };

  return (
    <div className="relative overflow-hidden flex flex-col lg:flex-row items-start justify-between gap-30 pt-16 md:pt-[120px] lg:pr-[64px] px-5">
      <img
        src={FooterDottedBg}
        alt="footer-bg"
        className="hidden sm:block absolute z-[-1] bottom-0 right-0 w-full"
      />
      <video
        autoPlay
        muted
        loop
        playsInline
        controlsList="nodownload"
        controls={false}
        preload="none"
        className="absolute right-[-120px] md:right-[-80px] bottom-[-100px] md:bottom-0 lg:bottom-[-160px] lg:left-[-120px] xl:left-[-50px] z-[-1] w-[460px] md:w-[400px] lg:w-[660px]">
        <source src={FooterVideo} type="video/mp4" playsInline />
      </video>
      <div className="flex w-full lg:justify-center pb-14">
        <img src={Logo} alt="logo" className="w-[200px] md:w-[260px]" />
      </div>

      <div>
        <h1 className="pb-3 text-base">
          Do you think you have what it takes?{" "}
          <span className="uppercase">come join us.</span>
        </h1>
        <p className="text-base underline underline-offset-[6px]">
          ideas@skclaccelerator.com
        </p>
        <div className="grid grid-cols-2 pt-10 md:grid-cols-3 md:gap-0">
          <div>
            <h2 className="pb-6 text-2xl leading-normal">
              {companyData.title}
            </h2>
            <ul className="flex flex-col items-start gap-5">
              {companyData.links.map(({ option, url }, i) => (
                <Link key={i} to={url} className="cursor-pointer">
                  {option}
                </Link>
              ))}
            </ul>
          </div>
          <div>
            <h2 className="pb-6 text-2xl leading-normal">Office</h2>
            <p className="pb-6 text-base md:w-auto">
              <span className="font-bold">SKCL PRIME</span>, 9th Floor <br />{" "}
              C-46, CIPET Road, Thiru Vi Ka Industrial Estate, SIDCO Industrial
              Estate, Guindy, Chennai, Tamil Nadu-600032.
            </p>
          </div>
        </div>

        <div className="flex flex-col justify-between gap-6 text-sm pt-[40px] md:pt-[90px] pb-[60px]">
          <div className="flex flex-col gap-6 md:flex-row whitespace-nowrap">
            <div className="flex gap-6">
              <Link to="/terms-of-use">Terms of use</Link>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </div>
            {/* <Link href="code-of-conduct">Code of Conduct</Link> */}
          </div>
          <p className="leading-6">
            Copyright 2024. <br className="sm:hidden" />
            All Rights Reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
