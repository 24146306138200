// "use client";
import { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
// import Image from "next/image";
import image2 from "./../../assets/images/home/nimble-box.svg";
import image3 from "./../../assets/images/home/mindler.svg";
import image4 from "./../../assets/images/home/quick-shift.svg";
import image5 from "./../../assets/images/home/sasvitha.svg";
import image6 from "./../../assets/images/home/plugzmart.svg";
import image7 from "./../../assets/images/home/tune.svg";

const ClientMarquee1 = () => {
  const slider = useRef(null);
  const firstText = useRef(null);
  const secondText = useRef(null);
  const thirdText = useRef(null);

  let xPercent = 0;
  let direction = -1;

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.to(slider.current, {
      scrollTrigger: {
        trigger: document.documentElement,
        scrub: 0.25,
        start: 0,
        end: window.innerHeight,
        onUpdate: (e) => (direction = e.direction * -0.8),
      },
      x: "-500px",
    });
    requestAnimationFrame(animate);
  }, []);

  const animate = () => {
    if (xPercent < -100) {
      xPercent = 0;
    } else if (xPercent > 0) {
      xPercent = -100;
    }
    gsap.set(firstText.current, { xPercent: xPercent });
    gsap.set(secondText.current, { xPercent: xPercent });
    gsap.set(thirdText.current, { xPercent: xPercent });
    requestAnimationFrame(animate);
    xPercent += 0.1 * direction;
  };

  return (
    <div ref={slider} className="relative flex whitespace-nowrap">
      <Phrase sliderRef={firstText} />
      <Phrase sliderRef={secondText} />
      <Phrase sliderRef={thirdText} />
    </div>
  );
};

export default ClientMarquee1;

const Phrase = ({ sliderRef }) => {
  const imgArr1 = [image2, image3, image4, image5, image6, image7];

  return (
    <div ref={sliderRef} className="absoltue left-[100%] top-0 m-0 flex">
      <div className="flex gap-2 mr-2">
        {imgArr1.map((src, i) => (
          <div className="w-[166px] md:w-[240px] mr-2" key={i}>
            <img src={src} alt="imgs" className="h-full mr-2 rounded-md" />
          </div>
        ))}
      </div>
    </div>
  );
};
