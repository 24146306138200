// // "use client";
import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
// import Image from "next/image";

const AnimatedLogos = ({ props }) => {
  const [currentLogoIndex, setCurrentLogoIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentLogoIndex((prevIndex) => (prevIndex + 1) % props.length);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  const variants = {
    up: {
      initial: { y: 200, opacity: 0 },
      animate: { y: 0, opacity: 1 },
      exit: { y: -200, opacity: 0 },
    },
    down: {
      initial: { y: -200, opacity: 0 },
      animate: { y: 0, opacity: 1 },
      exit: { y: 200, opacity: 0 },
    },
    left: {
      initial: { x: 200, opacity: 0 },
      animate: { x: 0, opacity: 1 },
      exit: { x: -200, opacity: 0 },
    },
    right: {
      initial: { x: -200, opacity: 0 },
      animate: { x: 0, opacity: 1 },
      exit: { x: 200, opacity: 0 },
    },
  };

  const funcdirection = (direction) => {
    const directions = direction;
    return directions[currentLogoIndex % directions.length];
  };

  return (
    <div className="grid items-center grid-cols-2 gap-10 overflow-hidden lg:grid-cols-4 lg:gap-28">
      {props.map(({ direction, imgSrc }, index) => (
        <div
          className="relative overflow-hidden flex justify-center items-center w-auto lg:w-[20vw] h-[100px] lg:h-[20vh] "
          key={index}>
          <AnimatePresence>
            <motion.div
              key={`${currentLogoIndex}-${index}`}
              className="h-[100px] lg:h-[100px] lg:w-[200px] absolute"
              variants={variants[funcdirection(direction)]}
              initial="initial"
              animate="animate"
              exit="exit"
              transition={{
                duration: 1,
                ease: [0.76, 0, 0.24, 1],
              }}>
              <img
                src={imgSrc[currentLogoIndex]}
                alt={`Logo ${currentLogoIndex + 1}`}
              />
            </motion.div>
          </AnimatePresence>
        </div>
      ))}
    </div>
  );
};

export default AnimatedLogos;
