// "use client";
import React from "react";
import { Link } from "react-router-dom";
import RightArrow from "./../../assets/images/home/right-arrow2.svg";
// import Image from "next/image";
import AnimTextPulse from "./animeTextPulse";
import HeroBg from "./../../assets/videos/home-bg.mp4";

const Hero = () => {
  return (
    <div className="relative h-[90vh] text-white" id="hero">
      <video
        autoPlay
        muted
        loop
        playsInline
        controlsList="nodownload"
        controls={false}
        preload="none"
        className="absolute z-[-1] rounded-tl-[40px] md:rounded-tl-[60px] rounded-tr-[40px] md:rounded-tr-[60px] w-full h-full object-cover opacity-80 inset-0">
        <source src={HeroBg} type="video/mp4" playsInline />
      </video>
      <div className="z-[-1] absolute inset-0 bg-black opacity-20 rounded-tl-[40px] md:rounded-tl-[60px] rounded-tr-[40px] md:rounded-tr-[60px]"></div>

      <div className="flex flex-col justify-center items-start h-[90vh] px-5 lg:pl-[80px]">
        <h1 className="text-[44px] leading-[54px] lg:text-[82px] lg:leading-[88px] pb-4 lg:pb-4 font-light">
          Home of the <br />{" "}
          <span>
            <AnimTextPulse />
          </span>
        </h1>
        <p className="text-base lg:text-[20px] lg:leading-[48px] pb-3 lg:pb-5 font-bold">
          Be a part of SKCL accelerator!
        </p>
        <Link
          to="/apply-now"
          className="flex items-center gap-[8px] md:gap-[18px] text-[18px] md:text-2xl border-[#CAFF31] border-[2px] rounded-[36px] px-[14px] md:px-4 py-[6px] md:py-2">
          <img src={RightArrow} alt="arrow" /> Apply Now
        </Link>
      </div>
    </div>
  );
};

export default Hero;
