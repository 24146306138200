// "use client";
import { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";

const Marquee2 = () => {
  const slider = useRef(null);
  const firstText = useRef(null);
  const secondText = useRef(null);
  const thirdText = useRef(null);
  const fourthText = useRef(null);

  let xPercent = 0;
  let direction = 1;

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.to(slider.current, {
      scrollTrigger: {
        trigger: document.documentElement,
        scrub: 0.25,
        start: 0,
        end: window.innerHeight,
        onUpdate: (e) => (direction = e.direction * 0.8),
      },
      x: "500px",
    });
    requestAnimationFrame(animate);
  }, []);

  const animate = () => {
    if (xPercent < -100) {
      xPercent = 0;
    } else if (xPercent > 0) {
      xPercent = -100;
    }
    gsap.set(firstText.current, { xPercent: xPercent });
    gsap.set(secondText.current, { xPercent: xPercent });
    gsap.set(thirdText.current, { xPercent: xPercent });
    gsap.set(fourthText.current, { xPercent: xPercent });
    requestAnimationFrame(animate);
    xPercent += 0.1 * direction;
  };

  return (
    <div ref={slider} className="relative flex whitespace-nowrap">
      <Phrase sliderRef={firstText} />
      <Phrase sliderRef={secondText} />
      <Phrase sliderRef={thirdText} />
      <Phrase sliderRef={fourthText} />
    </div>
  );
};

export default Marquee2;

const Phrase = ({ sliderRef }) => {
  return (
    <div ref={sliderRef}>
      <div className="w-[1000px] xl:ml-[500px] xl:mr-[500px]">
        <p className="text-[128px] lg:text-[185px] font-medium leading-[108px] lg:leading-[160px] custom-gradient">
          Vision
        </p>
      </div>
    </div>
  );
};
