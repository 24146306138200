// "use client";
import React, { useState, useEffect, useRef } from "react";
import Bg from "./../../assets/images/home/interest-bg.png";
// import Image from "next/image";
import {
  motion,
  AnimatePresence,
  useScroll,
  useTransform,
} from "framer-motion";
import Element1 from "./../../assets/images/home/element1-v2.png";

const InterestSection = () => {
  const [currentLogoIndex, setCurrentLogoIndex] = useState(0);

  const textArr = [
    "demand 15% of your company.",
    "take several weeks to decide to invest.",
    "charge fees.",
    "require fancy MBA's.",
    "tell you what to do. We only offer advice.",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentLogoIndex((prevIndex) => (prevIndex + 1) % textArr.length);
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  const variants = {
    down: {
      initial: { y: 100, opacity: 0 },
      animate: { y: 0, opacity: 1 },
      exit: { y: -100, opacity: 0 },
    },
  };

  const direction = ["down"];

  // For Parallex Image Scroll
  const scrollRef = useRef();
  const { scrollYProgress } = useScroll({
    target: scrollRef,
    offset: ["start end", "end start"],
  });

  const sm = useTransform(scrollYProgress, [0, 1], [0, -150]);
  // Parallex Image Scroll ends

  return (
    <div className="relative h-[480px] lg:h-[700px] w-full z-10">
      <motion.div
        style={{ y: sm }}
        className="absolute right-[15px] lg:right-[30px] bottom-[-220px]"
        ref={scrollRef}>
        <img
          src={Element1}
          alt="3d-element"
          className="w-[300px] lg:w-[450px]"
        />
      </motion.div>

      <img
        src={Bg}
        alt="bg"
        className="h-[564px] lg:h-[708px] w-full absolute left-0 right-0 z-[-1]"
      />
      <div className="pt-[85px] pl-5 lg:pl-[62px] text-white">
        <h2 className="text-2xl lg:text-[40px] lg:leading-[48px] font-light pb-[88px]">
          We prioritise the <br />
          founder's vision
        </h2>
        <div className="flex">
          <p className="absolute pb-2 text-base lg:text-5xl font-bold left-[20px] lg:left-[62px] xl:left-[18%]">
            We don't
          </p>
          <AnimatePresence>
            <motion.p
              key={currentLogoIndex}
              className="absolute left-[20px] lg:left-24 right-0 lg:mx-auto mt-10 lg:mt-20 text-[32px] leading-[40px] lg:text-[60px] lg:leading-[68px] font-semibold mix-blend-difference w-[310px] lg:w-[700px] xl:w-[800px]"
              variants={variants[direction]}
              initial="initial"
              animate="animate"
              exit="exit"
              transition={{
                duration: 1,
                ease: "easeInOut",
              }}>
              {textArr[currentLogoIndex]}
            </motion.p>
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default InterestSection;
