// "use client";
import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

const texts = ["Change Makers", "Disruptors", "Innovators"];

const AnimTextPulse = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative w-full pb-20">
      <AnimatePresence>
        <motion.div
          key={currentIndex}
          className="absolute w-full whitespace-nowrap text-[#CAFF31] font-bold text-4xl leading-10 md:text-[82px] md:leading-[88px]"
          initial={{ opacity: 0, y: 0 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 0 }}
          transition={{ duration: 0.5 }}>
          {texts[currentIndex]}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default AnimTextPulse;
