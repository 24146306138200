const CircledArrowSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="43"
      height="43"
      viewBox="0 0 43 43"
      fill="none">
      <rect
        x="8.11914"
        y="7.2229"
        width="27.447"
        height="26.0449"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.4769 42.4255C33.1924 42.4255 42.6897 32.9282 42.6897 21.2128C42.6897 9.49728 33.1924 0 21.4769 0C9.76144 0 0.26416 9.49728 0.26416 21.2128C0.26416 32.9282 9.76144 42.4255 21.4769 42.4255ZM22.3313 10.3082C21.9383 9.92011 21.3052 9.92404 20.9171 10.317C20.529 10.7099 20.5329 11.3431 20.9259 11.7312L29.2347 19.9373H11.835C11.2827 19.9373 10.835 20.3851 10.835 20.9373C10.835 21.4896 11.2827 21.9373 11.835 21.9373H29.2347L20.9259 30.1435C20.5329 30.5316 20.529 31.1648 20.9171 31.5577C21.3052 31.9506 21.9383 31.9546 22.3313 31.5665L32.373 21.6488L33.0934 20.9373L32.373 20.2259L22.3313 10.3082Z"
        fill="white"
      />
    </svg>
  );
};

export default CircledArrowSvg;
