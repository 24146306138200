import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import "./index.css";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import Home from "./page/Home.jsx";
import ApplyNow from "./page/ApplyNow.jsx";
import NotFound from "./page/not-found.jsx";
import About from "./page/About.jsx";
import Clientele from "./page/Clientele.jsx";
import TermsofUse from "./page/TermsofUse.jsx";
import PrivacyPolicy from "./page/PrivacyPolicy.jsx";
import Blog from "./page/Blog.jsx";
import BlogTemplate from "./page/BlogTemplate.jsx";
import Partners from "./page/Partners.jsx";
import Testimonials from "./components/home/clients.jsx";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route index={true} path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/clientele" element={<Clientele />} />
      <Route path="/partners" element={<Partners />} />
      <Route path="/blogs" element={<Blog />} />
      <Route path="/blog-template" element={<BlogTemplate />} />

      <Route path="/terms-of-use" element={<TermsofUse />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/apply-now" element={<ApplyNow />} />
      {/* <Route path="/testimonials" element={<Testimonials />} /> */}

      <Route path="*" element={<NotFound />} />
    </Route>
  )
);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
