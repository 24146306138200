import React from "react";
import { Link } from "react-router-dom";

const howWeUse = [
  "To provide and administer access to the Services;",
  "To grant access to and track attendance at our events;",
  "To respond to your inquiries, comments, feedback, or questions;",
  "To send administrative information to you, for example, information regarding the Services and changes to our terms, conditions, and policies;",
  "To analyze how you interact with our Services;",
  "To maintain and improve the content and functionality of the Services;",
  "To develop new programs and services;",
  "To prevent fraud, criminal activity, or misuses of our Services, and to ensure the security of our IT systems, architecture, and networks;",
  "To ensure compliance with sanctions regulations;",
  "To comply with legal obligations and legal process and to protect our rights, privacy, safety, or property, and/or that of our Affiliates, you, or other third parties.",
];

const deletionReq = [
  "If you would like to request that we delete your entire SKCL Accelerator account and all Personal Information associated with it, contact us at privacy@ycombinator.com.",
  "If you would like to request that we delete your Application Information but otherwise maintain your SKCL Accelerator account, contact us at apply@ycombinator.com.",
  "If you would like to request that we delete your WaaS Information but otherwise maintain your SKCL Accelerator account, contact us at workatastartup@ycombinator.com. ",
];

const updateReq = [
  "Please log in to your SKCL Accelerator account to change or correct your Personal Information. If you need help, contact us at accounts@ycombinator.com.",
  "With respect to Application Information, before you submit your application, you can update your Application Information by logging in and editing your application directly.",
  "If you need help updating your WaaS information, you can contact us at workatastartup@ycombinator.com.",
  "For Hacker News account recovery, contact us at hn@ycombinator.com.",
];

// export const metadata = {
//   title: "Privacy Policy",
//   description: "This is a Privacy Policy Page",
// };

const PrivacyPolicy = () => {
  return (
    <div>
      <div className=" bg-[#A6F5C5] py-[128px] rounded-tl-[40px] md:rounded-tl-[60px] rounded-tr-[40px] md:rounded-tr-[60px]">
        <h1 className="text-[40px] leading-normal font-light text-center">
          Privacy Policy
        </h1>
      </div>

      <div className="container max-w-[1280px] mx-auto px-5 md:px-20">
        <div>
          <p className="pt-16 pb-8 text-base italic">
          We are committed to protecting and respecting your privacy. Please read this document carefully as it provides necessary information in regards to the information you share on our platform including but not limited to this Website/ Mobile Application.
          </p>

          <p className="pb-8 text-base">
          The website/ any other electronic medium of communication (“Communication Medium”) is solely for the users of ++++++ and its subdomains. These are managed and operated by SKCL. (hereafter “SKCL”) either by itself, or through third party vendors.
          </p>

          <p className="pb-8 text-base">
          The terms “We”, “Us”, “Our”, wherever used refer to SKCL, which expression shall mean and include its affiliates, group entities, successors and permitted assigns.
          </p>

          <p className="pb-8 text-base">
          This Privacy Policy constitutes a legal agreement between You, the user of the Communication Medium, and SKCL.
          </p>

          <p className="pb-8 text-base">
          This Policy has been drafted in compliance with the applicable data protection rules and regulations of India and shall by inference include and comply with any amendments thereto unless expressly excluded. This Policy shall be subject to periodic internal review and the same shall be updated regularly to abide by evolving data protection rules and regulations.
          </p>

          <p className="pb-8 text-base">
          WHEN YOU USE THE COMMUNICATION MEDIUM YOU ARE VOLUNTARILY PROVIDING US WITH THE INFORMATION REQUESTED (INCLUDING ANY INFORMATION THAT MAY QUALIFY AS PERSONALLY IDENTIFIABLE INFORMATION) AND CONSENTING TO OUR USE THEREOF IN ACCORDANCE WITH THIS POLICY.
          </p>
        </div>

        <div>
          <h1 className="pb-4 text-3xl leading-10">
            1. Applicability:
          </h1>
          <p className="pb-4">
          This privacy policy (Policy) describes Our policies and procedures on the collection, use, storage processing and disclosure of any information including but not limited to, business or personal information provided by You while using Our Communication Medium
          </p>
          <p className="pb-4">
          Your use of the Communication Medium will be governed by this Policy in addition to the Terms of Use as applicable to You. The Policy together with the Terms of Use are referred to as the Documents.
          </p>
          <p className="pb-4">
          During your association with Us, You may be required to execute certain other commercial agreements (Commercial Agreements) and such Commercial Agreements shall govern Your business relationship with Us as per the terms thereof. In the event of any conflict between such agreement and the terms enforced under this Policy, the terms of the Commercial Agreement shall prevail
          </p>
        </div>

        <div className="pt-4">
          <h1 className="pb-4 text-3xl leading-10">
            2. Collection of Information:
          </h1>
          <p className="pb-4">
          We will only collect the information provided by you via the Communication Medium (“Information”). We will use the Information only for the purposes described in this Policy and in accordance with the Documents.
          </p>
          <p className="pb-4">
          During Your use of Our Communication Medium, We may collect and process such Information from You, including but not limited to:
          </p>

          <p className="pb-4">
          (a) Information that You provide to Us by filling in forms on our Communication Medium. This may include personally identifiable information such as name, email address, mailing address, phone number, financial information etc., Unique identifiers such as user name, account number, password and preferences information such as favourites lists, transaction history etc.;
          </p>
          <p className="pb-4">
          (b) Information relating to Your business provided by you on the Communication Medium.
          </p>
          <p className="pb-4">
          (c) Information that You provide when You write directly to Us (<a href="mailto:ideas@skclaccelerator.com">ideas@skclaccelerator.com</a>);
          </p>
          <p className="pb-4">
          (d) Information that You provide to Us over telephone. We may make and keep a record the information You share with us;
          </p>
          <p className="pb-4">
          (e) Information relating to logs that is automatically reported by Your device each time You access the Communication Medium. When You use our Communication Medium, Our servers automatically record certain information that Your device sends whenever You visit our Communication Medium.
          </p>
        </div>

        <div className="pt-4">
          <h1 className="pb-4 text-3xl leading-10">
            3. Cookies:
          </h1>
          <p className="pb-4">
          “Cookies” are small files that reside on Your device’s hard drive and generally contain an anonymous unique identifier and are accessible by the Communication Medium that placed them there. Cookies do a lot of different jobs, such as letting you navigate between pages efficiently, remembering your preferences and generally improving the user experience. In addition, cookies can help Us to analyze the use of our Communication Medium and online content (analytics cookies) and they can also facilitate/track the interaction on our Communication Medium and online content with social media (e.g. links to social media sites, like buttons, etc.).
          </p>
          <p className="pb-4">
          We can place cookies and other similar technology on Your device, including mobile device, in accordance with your preferences set on our cookie consent manager. Depending on your settings in our cookie consent manager on your device, the following information may be collected through cookies or similar technology: your unique device identifier, device IP address, information about your device’s operating system, mobile carrier and your location information (to the extent permissible under applicable law).
          </p>
          <p className="pb-4">
          Most browsers allow you to manage how cookies are set, and to clear cookies and browsing data. Also, your browser may have settings allowing you to manage cookies on a site-by-site basis. For example, browser settings may allow you to delete existing cookies, allow or block all cookies, and set cookie preferences for websites. Certain browsers also have incognito/ Privacy mode, which doesn’t store your online history of visited sites or cookies on your device after you close all incognito windows.
          </p>
          <p className="pb-4">
          You may refuse to accept Cookies by activating the setting on Your browser which allows You to refuse the setting of Cookies. However, if You select this setting you may be unable to access certain parts of Our Communication Medium. Unless You have adjusted Your browser setting so that it will refuse Cookies, Our system may issue Cookies when You log on to our Communication Medium.
          </p>
          <p className="pb-4">
          Some cookies, web beacons and other tracking and storage technologies that We use are from third party companies (third party cookies), such as, but not limited to, Facebook, Google Analytics, Microsoft, Twitter, YouTube, Instagram, and Linkedin Analytics. These provide Us with web analytics and intelligence about our Communication Medium which may also be used to provide services and targeted ads. The use of such third party cookies is not covered by this Privacy Policy. Please refer to the respective privacy policy of such third party companies for the same.
          </p>
          <p className="pb-4">
          Additionally, We may use the services of certain third parties for the purpose of operating and administering the Communication Medium. Such third-party service providers may collect the information sent by You as part of a web page request, including Cookies and your IP address and such information will be governed by the privacy policies of the third-party service providers.
          </p>
        </div>

        <div className="pt-4">
          <h1 className="pb-4 text-3xl leading-10">
            4. Access to & Use of Information:
          </h1>
          <p className="pb-4">
          Access to Your Information will be restricted to persons associated with Us  who have been specifically authorized to access and view such Information by Us.
          </p>
          <p className="pb-4">
          We may use the Information provided by You in the following ways, viz:
          </p>
          <p className="pb-4">
          (a) monitor, improve and administer Our Communication Medium;
          </p>
          <p className="pb-4">
          (b) conduct research and analysis;
          </p>
          <p className="pb-4">
          (c) analyze how the Communication Medium is used, diagnose service or technical problems, maintain security;
          </p>
          <p className="pb-4">
          (d) remember information to help You efficiently access the Communication Medium;
          </p>
          <p className="pb-4">
          (e) monitor aggregate metrics such as total number of viewers, visitors, traffic, and demographic patterns;
          </p>
          <p className="pb-4">
          (f) to confirm Your identity in order to ensure that You are eligible to use the Communication Medium
          </p>
          <p className="pb-4">
          (g) to ensure that content from Our Communication Medium is presented in the most effective manner based upon Your interests;
          </p>
          <p className="pb-4">
          (h) to contact You to ensure user satisfaction with respect to Your use of the Communication Medium;
          </p>
          <p className="pb-4">
          (i) to provide You with information that You request from Us, where You have consented to be contacted for such purposes;
          </p>
          <p className="pb-4">
          (j) to carry out Our obligations arising from any contracts entered into between You and Us;
          </p>
          <p className="pb-4">
          (k) to notify You about changes on Our Communication Medium;
          </p>
          <p className="pb-4">
          (l) to enable Us to comply with Our legal and regulatory obligations;
          </p>
          <p className="pb-4">
          We may also use Your information, and/ or permit selected third parties including service providers, on the Communication Medium, with Your prior consent, to use Your information or provide You with such information which may be of interest to You and We and/ or they may contact You about the same. If You agree to be so contacted by the third parties, any information You provide to such third parties may be read, collected, and used by them. Notwithstanding the above mentioned, You may choose to stop receiving such information from the third parties by writing to Us at (<a href="mailto:ideas@skclaccelerator.com">ideas@skclaccelerator.com</a>)
          </p>
        </div>

        <div className="pt-4">
          <h1 className="pb-4 text-3xl leading-10">
            5. Disclosure of Information
          </h1>
          <p className="pb-4">
          We will share Your information with third parties only in such manner as described below:
          </p>
          <p className="pb-4">
          (a) We may share the Information with third parties and in such an event, the third parties’ use of Your information will be bound by the Policy. We may store information in locations outside Our direct control (for instance, on servers or databases co- located with third-party hosting providers)
          </p>
          <p className="pb-4">
          (b) We may disclose Your information to any member of the network
          </p>
          <p className="pb-4">
          (c) We may disclose Your Information to third-party service-providers solely in the course of their provision of services to Us. We will take reasonable precautions to ensure that these service-providers are obligated to maintain the confidentiality of Your Information.
          </p>
          <p className="pb-4">
          (d) We may disclose Your Information if We are under a duty to do so in order to comply with any legal obligation, or in order to enforce or apply Our Terms of Use, or to protect the rights, property, or Our, Our users, or others safety. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.
          </p>
          <p className="pb-4">
          (e) We may disclose Your Information to governmental and other statutory bodies who have appropriate authorization to access the same for any legal purposes.
          </p>
          <p className="pb-4">
          (f) We may share Your Information with our members, advisors, consultants, directors, employees (without limitation). We strongly recommend You not to share any sensitive, proprietary, and/or competitive information that may compromise the confidentiality of Your proposition.
          </p>
        </div>

        <div className="pt-4">
          <h1 className="pb-4 text-3xl leading-10">
            6. Your Choices about your information
          </h1>
          <p className="pb-4">
          You may decline to submit identifiable information through the Communication Medium, in which case You may not be allowed to access certain features / parts of the Communication Medium. If You are a registered user, you may update or correct your account information and email preferences at any time by logging in to your account. Alternatively, if You believe that any of Your Information held by Us is inaccurate, You may write to Us at [●]. It is Your responsibility to ensure that any information You provide Us remains accurate and updated.
          </p>
          <p className="pb-4">
          We will retain and use Your Information as necessary to comply with Our legal obligations, resolve disputes, and enforce Our agreements.
          </p>
          <p className="pb-4">
          You have the following rights in relation to Your Information:
          </p>
          <ul>
            <li className="pb-4">To access the Information held by Us about you</li>
            <li className="pb-4">To have your Information corrected, for example, if it is incomplete or incorrect</li>
            <li className="pb-4">To opt out of receiving marketing communications at any time</li>
            <li className="pb-4">To restrict or object to the processing of Information or request erasing Information (in certain circumstances and subject to applicable law)</li>
            <li className="pb-4">
            To receive a copy of the Information which you have provided to Us, in a structured, commonly used and machine-readable format (in certain circumstances and subject to applicable law)
            </li>
          </ul>
          <p className="pb-4">
          Where you have provided Information voluntarily, or otherwise consented to its use, the right to withdraw your consent
          </p>
          <p className="pb-4">
          The right to complain to a data protection authority
          </p>
          <p className="pb-4">
          If you have a query or wish to exercise your rights, please contact [<a href="mailto:ideas@skclaccelerator.com">ideas@skclaccelerator.com</a>]
          </p>
        </div>

        <div className="pt-4">
          <h1 className="pb-4 text-3xl leading-10">
            7. Storage and Security
          </h1>
          <p className="pb-4">
          As a registered user with an account and a password, You are responsible for implementing an appropriately strong password, and keeping Your password confidential.
          </p>
          <p className="pb-4">
          All information You provide to Us is stored on secured servers whether outside or within India. However, please bear in mind that the secure servers are not controlled by Us and are managed and operated by third party service providers.
          </p>
          <p className="pb-4">
          These servers use commercially available tools provided by such third party storage providers to provide appropriate safeguards to preserve the integrity and security of Your information against loss, theft, unauthorized access, disclosure, reproduction, use or amendment.
          </p>
          <p className="pb-4">
          The information that We collect from You may be transferred to, and stored at, a destination inside or outside India. By submitting Your information on Our Communication Medium, You agree to this transfer, storing and/ or processing. We will take such steps as we consider reasonably necessary to ensure that Your information is treated securely and in accordance with this Privacy Policy.
          </p>
          <p className="pb-4">
          It is our policy to only use third-party support providers that are bound to maintain appropriate levels of data protection, security and confidentiality, and those that comply with applicable legal requirements for transferring Information outside the jurisdiction in which it was originally collected.
          </p>
          <p className="pb-4">
          However, in using the Communication Medium, You accept the inherent security implications of data transmission over the internet and the world wide web. You data cannot always be guaranteed as being completely secure. Therefore, Your use of the Communication Medium will be at Your own risk.
          </p>
          <p className="pb-4">
          WE ASSUME NO LIABILITY FOR ANY DISCLOSURE OF INFORMATION DUE TO ERRORS IN TRANSMISSION, UNAUTHORIZED THIRD PARTY ACCESS OR OTHER ACTS OF THIRD PARTIES, OR ACTS OR OMISSIONS BEYOND OUR REASONABLE CONTROL AND YOU AGREE THAT YOU WILL NOT HOLD US RESPONSIBLE FOR ANY BREACH OF SECURITY UNLESS SUCH BREACH HAS BEEN CAUSED AS A DIRECT RESULT OF OUR GROSS NEGLIGENCE OR WILFUL DEFAULT.
          </p>
          <p className="pb-4">
          In the event that We become aware of any breach of the security of Your information, We will promptly notify You and take appropriate action to the best of Our ability to remedy such a breach.
          </p>
          <p className="pb-4">
          If you know or have reason to believe that your account credentials have been lost, stolen, altered, or otherwise compromised, or in case of any actual or suspected unauthorized use of your account, please contact us by contacting the Grievance Officer  at [<a href="mailto:ideas@skclaccelerator.com">ideas@skclaccelerator.com</a>] 
          </p>
        </div>

        <div className="pt-4">
          <h1 className="pb-4 text-3xl leading-10">
            8. Retention of Information
          </h1>
          <p className="pb-4">
          Our policy is to retain the Information only for as long as it is needed for the purposes described herein. In order to meet our professional and legal requirements, to establish, exercise or defend our legal rights, and for archiving and historical purposes we may need to retain Information for significant periods of time.
          </p>
        </div>

        <div className="pt-4">
          <h1 className="pb-4 text-3xl leading-10">
            9. Changes to this Privacy Policy
          </h1>
          <p className="pb-4">
          We will occasionally update this Privacy Policy to reflect changes in our practices and services. When We post changes to this Privacy Policy, we will revise the “last updated” date at the top of this Privacy Policy. If we make any material changes in the way We collect, use, and share the Information, We will notify you by prominently posting notice of the changes on the Communication Medium. We recommend that you check this page from time to time to inform Yourself of any changes in this Privacy Policy. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.
          </p>
        </div>

        <div className="pt-4">
          <h1 className="pb-4 text-3xl leading-10">
            10. Contact Us
          </h1>
          <p className="pb-4">
          If you have any questions or comments about our policy outlined above or would like to report any grievances, you can contact us at:
          </p>

          <p className="pb-4">
            Name: Sanjhana
          </p>
          {/* <p className="pb-4">
            Designation: 
          </p> */}
          <p className="pb-4">
            Email : <a href="mailto:ideas@skclaccelerator.com">ideas@skclaccelerator.com</a>
          </p>
        </div>

      </div>
    </div>
  );
};

export default PrivacyPolicy;
