// "use client";
import React from "react";
// import Image from "next/image";
import SlideUp from "../slideUp";
import TechImg from "./../../assets/images/home/tech-img.png";
import AiImg from "./../../assets/images/home/ai-img.png";
import EnterpriseImg from "./../../assets/images/home/enterprise.png";
import focusVideo from "./../../assets/videos/FocusAreas.mp4";

const FocusAreaSection = () => {
  return (
    <div
      className="flex flex-col lg:px-[100px] pt-[64px] pb-[100px]">
      <div className="container max-w-[1440px] mx-auto flex flex-col sm:flex-row sm:items-center justify-between pb-6 px-5 lg:pl-0 lg:pb-8 relative overflow-hidden">
        <div>
          <p className="bg-[#CAFF31] inline-block px-4 py-2 rounded-[40px] text-[#626262] mb-5">
            Focus Areas
          </p>
          <SlideUp custom={0.5}>
            <h1 className="text-[28px] leading-[42px] lg:text-[36px] lg:leading-[44px] w-[340px] lg:w-[420px] xl:w-[522px] font-light text-[#626262]">
              Key Strategic Focus Areas Driving Innovation, Sustainability, and
              Growth
            </h1>
          </SlideUp>
        </div>
        <video
          autoPlay
          muted
          loop
          playsInline
          controlsList="nodownload"
          preload="auto"
          className="w-full sm:w-[400px] md:w-[460px] lg:w-[480px] xl:w-[660px]">
          <source src={focusVideo} type="video/mp4" playsInline />
        </video>
      </div>

      {/* New Image Container */}
      <div className="container max-w-[1440px] mx-auto flex flex-col md:flex-row gap-10 px-5 lg:px-0">
        <SlideUp custom={0.6}>
          <div className="relative w-auto lg:h-full">
            <img
              src={TechImg}
              alt="tech-img"
              placeholder="blur"
              className="object-cover h-full rounded-xl"
            />
            <p className="absolute text-[20px] text-white lg:leading-8 lg:text-2xl bottom-4 md:bottom-6 left-4">
              Technology & Inovation
            </p>
          </div>
        </SlideUp>

        <SlideUp custom={0.7}>
          <div className="relative w-auto lg:h-full">
            <img
              src={AiImg}
              alt="tech-img"
              placeholder="blur"
              className="object-cover h-full rounded-xl"
            />
            <p className="absolute text-[20px] text-white lg:leading-8 lg:text-2xl bottom-4 md:bottom-6 left-4">
              Artificial Intelligence
            </p>
          </div>
        </SlideUp>

        <SlideUp custom={0.8}>
          <div className="relative w-auto lg:h-full">
            <img
              src={EnterpriseImg}
              alt="tech-img"
              placeholder="blur"
              className="object-cover h-full rounded-xl"
            />
            <p className="absolute text-[20px] text-white lg:leading-8 lg:text-2xl bottom-4 md:bottom-6 left-4">
              Enterprise SAAS
            </p>
          </div>
        </SlideUp>
      </div>
    </div>
  );
};

export default FocusAreaSection;
