// "use client";
import React from "react";
import { motion } from "framer-motion";

const SlideUp = ({ children, custom }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: custom }}
      viewport={{ once: true }}>
      {children}
    </motion.div>
  );
};

export default SlideUp;
