// // "use client";
// import Head from "next/head";
// import Link from "next/link";
// import Image from "next/image";
import SlideUp from "../components/slideUp";
import UploadBtn from "./../assets/images/common/upload.svg";
import ApplynowBanner from "./../assets//videos/applynow-banner.mp4";
import DottedBg from "./../assets/images/common/apply-now-dotted.png";
import CircledArrowSvg from "../components/circledArrowSvg";
import axios from "axios";
import React, { Component, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import Api from "../../package.json";
import swal from "sweetalert2";

const data = [
  {
    num: "01",
    content: "Brief of the company.",
  },
  {
    num: "02",
    content: "Founder history, resume.",
  },
  {
    num: "03",
    content: "Presentation cover letter, pitch(slideshow & video pitch).",
  },
  {
    num: "04",
    content: "Idea,Problem Statement,Solution.",
  },
  {
    num: "05",
    content: "Why You?",
  },

  {
    num: "06",
    content: "Revenue Statements/Financials.",
  },
  {
    num: "07",
    content: "Plan of Execution/Action for the next 3,5,7,10 years.",
  },
  {
    num: "08",
    content: "End Goal.",
  },
];

const ApplyNow = () => {
  const [isActive, setIsActive] = useState(false);
  const [onSubmit, setOnSubmit] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [document, setDocument] = useState(null);

  const handleFileChange = (e) => {
    // setDocument(e.target.files[0]);
  };
  const buttonRef = useRef();
  const [SubmitButton, setSubmitButton] = useState("submit");
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmitCareer = (data) => {
    buttonRef.current.disabled = true;
    setSubmitButton("SUBMITTING...");
    setOnSubmit(true);
    submit_form(data);
  };
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
  };
  const submit_form = async (data) => {
    console.log(data);

    const formData = new FormData();
    formData.append("fullname", data.fullname);
    formData.append("email", data.email);
    formData.append("mobilenumber", data.mobilenumber);
    formData.append("attachment", data.resume[0]);

    try {
      const response = await axios({
        method: "POST",
        url: "https://www.skclaccelerator.com/backend/index.php/wp-json/custom/v1/enquiry",
        data: formData,
        headers: headers,
      });
      swal.fire("Success !", "Successfully Submitted", "success");
    } catch (err) {
      if (err.response) {
        // Server responded with a status other than 200 range
        setError(`HTTP error! Status: ${err.response.status}`);
        swal.fire("Error !", `${err.response.status}`, "error");
      } else if (err.request) {
        // Request was made but no response received
        swal.fire("Error !", "No response received", "error");
        //setError('No response received');
      } else {
        // Something else caused the error
        //setError(err.message);
        swal.fire("Error !", err.message, "error");
      }
    }

    // buttonRef.current.disabled = false;
    setSubmitButton("Request a demo");
  };

  return (
    <>
      <div className="relative pt-[70px] pb-[90px] lg:px-[60px] lg:grid lg:grid-cols-2">
        <img
          src={DottedBg}
          alt="dotted-bg"
          className="absolute bottom-0 right-0 z-[-1] w-full"
        />
        <div className="relative px-5">
          <SlideUp custom={0.4}>
            <p className="mb-8 bg-[#CAFF31] rounded-[40px] inline-block px-4 py-2">
              Apply Now
            </p>
          </SlideUp>
          <SlideUp custom={0.5}>
            <h1 className="text-2xl font-light leading-9 w-[265px] md:w-[380px] lg:w-[380px]">
              Please submit your application online with a Pitch deck that
              furnishes the details requested <span className="md:hidden inline-block">below:</span>
            </h1>
          </SlideUp>
          <video
            autoPlay
            muted
            loop
            playsInline
            controlsList="nodownload"
            controls={false}
            preload="none"
            className="w-[600px] h-full lg:absolute lg:block lg:left-0 lg:top-0 mix-blend-multiply">
            <source src={ApplynowBanner} type="video/mp4" playsInline />
          </video>
        </div>

        <SlideUp custom={0.6}>
          <div className="px-5">
            <div className=" bg-[#DFFF84] rounded-[40px] py-[44px] px-6 md:px-[54px] h-full lg:h-[960px]">
              {isActive ? (
                <></>
              ) : (
                <div className="flex flex-col items-start gap-8">
                  {data.map(({ num, content }, i) => (
                    <div key={i}>
                      <p className="text-base text-[#777E90] pb-[6px]">{num}</p>
                      <h3 className="text-[19px] leading-normal">{content}</h3>
                    </div>
                  ))}
                  <button
                    className="flex items-center gap-5 text-[18px] leading-normal mt-[40px] lg:mt-[52px] border-[#C1C1C1] border-[2px] rounded-[36px] px-[14px] md:px-4 py-[6px] md:py-2"
                    onClick={() => setIsActive(!isActive)}>
                    <span>
                      <CircledArrowSvg />
                    </span>
                    Apply Now
                  </button>
                </div>
              )}

              {isActive && onSubmit === false && (
                <div className="">
                  <button
                    className="flex items-center gap-2 pb-6"
                    onClick={() => setIsActive(false)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="13"
                      viewBox="0 0 12 13"
                      fill="none">
                      <path
                        d="M11.4097 6.77654H0.710829M0.710829 6.77654L6.12717 1.42712M0.710829 6.77654L6.12717 12.126"
                        stroke="#23262F"
                        strokeLinecap="round"
                      />
                    </svg>{" "}
                    Back
                  </button>
                  <h1 className="text-[28px] font-light leading-10">
                    Get in touch
                  </h1>
                  <p className="pb-[52px]">
                    We'll get back to you as <br className="md:hidden" />
                    soon as we can.
                  </p>

                  <form method="POST" onSubmit={handleSubmit(onSubmitCareer)}>
                    <label className="flex flex-col text-[20px] leading-normal">
                      <span className="text-base text-[#777E90]">01</span>Enter
                      your Name
                    </label>
                    <input
                      type="text"
                      {...register("fullname", {
                        required: {
                          value: true,
                          message: "Please enter your name",
                        },
                      })}
                      className="border-[#B1B5C3] border-b-[0.5px] w-full bg-transparent pt-5 mb-6 outline-none"
                    />
                    {errors.fullname && (
                      <span class="errors">{errors.fullname.message}</span>
                    )}
                    <label className="flex flex-col text-[20px] leading-normal">
                      <span className="text-base text-[#777E90]">02</span>Enter
                      your Email
                    </label>
                    <input
                      type="email"
                      {...register("email", {
                        required: {
                          value: true,
                          message: "Please enter your email-id",
                        },
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Invalid email address",
                        },
                      })}
                      className="border-[#B1B5C3] border-b-[0.5px] w-full bg-transparent pt-5 mb-6 outline-none"
                    />
                    {errors.email && (
                      <span class="errors">{errors.email.message}</span>
                    )}
                    <label className="flex flex-col text-[20px] leading-normal">
                      <span className="text-base text-[#777E90]">03</span>Enter
                      your Phone number
                    </label>
                    <input
                      type="number"
                      {...register("mobilenumber", {
                        required: {
                          value: true,
                          message: "Please enter your mobile number",
                        },
                        minLength: {
                          value: 6,
                          message: "Invalid phone number",
                        },
                        maxLength: {
                          value: 15,
                          message: "Invalid phone number",
                        },
                        pattern: {
                          value: /^-?[0-9]\d*\.?\d*$/i,
                          message: "Invalid",
                        },
                      })}
                      className="border-[#B1B5C3] border-b-[0.5px] w-full bg-transparent pt-5 mb-6 outline-none"
                    />
                    {errors.mobilenumber && (
                      <span class="errors">{errors.mobilenumber.message}</span>
                    )}
                    <label className="flex flex-col text-[20px] leading-normal">
                      <span className="text-base text-[#777E90]">04</span>Attach
                      Document
                    </label>
                    <div className="relative flex">
                      <input
                        type="file"
                        accept=".pdf, .jpg, .png"
                        {...register("resume", {
                          required: {
                            value: true,
                            message: "Please attach Your document",
                          },
                        })}
                        className="border-[#B1B5C3] border-b-[0.5px] w-full bg-transparent pt-5 mb-6 file-input cursor-pointer outline-none"
                      />

                      <img
                        src={UploadBtn}
                        alt="upload-btn"
                        className="absolute right-0 top-4 md:top-2"
                      />
                    </div>
                    {errors.resume && (
                      <span class="errors">{errors.resume.message}</span>
                    )}
                    <button
                      className="flex items-center gap-5 text-[18px] leading-normal mt-[40px] lg:mt-[52px] border-[#C1C1C1] border-[2px] rounded-[36px] px-[14px] md:px-4 py-[6px] md:py-2"
                      type="submit"
                      ref={buttonRef}>
                      <span>
                        <CircledArrowSvg />
                      </span>
                      Submit
                    </button>
                  </form>
                </div>
              )}

              {onSubmit && (
                <div className="flex flex-col items-start">
                  <h1 className="text-[32px] leading-[40px] font-light pb-4">
                    Thank you. Our team will <br className="hidden md:block" />
                    review and revert shortly.
                  </h1>
                  <p className="text-[19px] leading-[28px] pb-[52px]">
                    Have a nice day & <br className="md:hidden" />
                    keep the zeal alive.
                  </p>
                  <Link
                    to="/"
                    className="flex items-center gap-5 text-[18px] leading-normal border-[#C1C1C1] border-[2px] rounded-[36px] px-[14px] md:px-4 py-[6px] md:py-2">
                    <span>
                      <CircledArrowSvg />
                    </span>
                    Return to Home
                  </Link>
                </div>
              )}
            </div>
          </div>
        </SlideUp>
      </div>
    </>
  );
};

export default ApplyNow;
