// import Head from "next/head";
// import Image from "next/image";
import { useState, useEffect, React, useRef } from "react";
import axios from "axios";
import Api from "../../package.json";
// import Link from "next/link";
import FeaturedImg from "./../assets/images/common/featured-img.png";
import UserImg from "./../assets/images/common/user-placeholder.png";
import BlogImg from "./../assets/images/common/blog-img.png";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { decodeEntity } from "html-entities";

// export const metadata = {
//   title: "Blog Article | SKCL",
//   description: "Created By FPF",
// };
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const blogs = [
  {
    blogTitle: "Lorem ipsum dolor sit amet conetiam quam in blandit massa.",
    category: "Success stories",
    imgUrl: BlogImg,
    blogUrl: "#",
  },
  {
    blogTitle: "Lorem ipsum dolor sit amet conetiam quam in blandit massa.",
    category: "News",
    imgUrl: BlogImg,
    blogUrl: "#",
  },
  {
    blogTitle: "Lorem ipsum dolor sit amet conetiam quam in blandit massa.",
    category: "Success stories",
    imgUrl: BlogImg,
    blogUrl: "#",
  },
];

const blogData = {
  blogTitle:
    "Lorem ipsum dolor sit amet consectetur. Nibh lectus massa tristique cursus eu ipsum diam",
  username: "John Wick",
  postedOn: "11 Jan 2024",
  category: "Featured",
  userImg: UserImg,
  featuredImg: FeaturedImg,
  content:
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt dignissimos, ea expedita cumque dolores cum nesciunt officiis est nihil consectetur blanditiis quasi fuga, voluptatem nam. Obcaecati harum iusto et autem, placeat numquam laborum corrupti ullam vitae eos quas reiciendis cupiditate.",
};

const BlogTemplate = () => {
  const [newsDetails, setNewsDetails] = useState([]);
  const [relatedNews, setRelatedNews] = useState([]);
  const [featuredImage, setFeaturedImage] = useState();
  const [authorDetails, setAuthorDetails] = useState([]);
  const [tittle, setTittle] = useState('');
  const [catName, setCatName] = useState('');
  const navigate = useNavigate();
  const { state } = useLocation();
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
  };

  const get_load_data = async () => {
    const response = await axios({
      method: "GET",
      url: Api.userdata.url + "get_news_details?id="+state.id,
      headers: headers,
    });
    const json = await response.data;
    if (json["status"] == "OK") {
      setNewsDetails(json["content"]);
      setRelatedNews(json["news_realted_post"]);
      setFeaturedImage(json["feat_img"]);
      setAuthorDetails((json["author_details"]));
      setTittle(json["title"]);
      setCatName(json["cat_name"]);
    } 


  };

  useEffect(() => {

    get_load_data();
 
  }, [state]);

  return (
    <>
      {/* <Head>
        <title>Blog Template Page</title>
        <meta name="description" content="This is a Blog Template page" />
        <meta name="keywords" content="blog template, nextjs, react" />
      </Head> */}

      <div className="">
        {/* Hero Section*/}
        <div className="flex flex-col items-center justify-center h-[530px] bg-[#F3F3F3] px-5 lg:px-[60px]">
          <p className="text-sm font-medium uppercase">{catName}</p>
          <h1 className="pb-6 text-3xl font-light leading-normal text-center lg:w-[900px]">
            {tittle}
          </h1>
          <div>
            <img
              src={authorDetails.author_image}
              alt="author"
              className="rounded-full w-[80px]"
            />
            <h5 className="pt-5 text-sm text-center">{authorDetails.author_name}</h5>
            <p className="text-sm leading-7 text-center">{authorDetails.posted_date}</p>
          </div>
        </div>

        {/* Blog Content Section */}
        <div className="relative container max-w-[990px] mx-auto px-5 pb-20 lg:px-[60px]">
          <img
            src={featuredImage}
            alt="featured-img"
            className="pb-14 mt-[-30px] lg:mt-[-40px] w-full lg:w-[947px]"
          />
        
          <div
                                  dangerouslySetInnerHTML={{
                                    __html: newsDetails,
                                  }}
                                ></div>
          {/* <p className="leading-8">{blogData.content}</p> */}
        </div>

        {/* Related Article Section */}
        <div className="bg-[#F7F7F7] py-20 px-5 lg:px-[60px]">
          <h1 className="pb-10 text-3xl font-light text-center lg:text-4xl">
            Related Articles
          </h1>
          <div className="relative container max-w-[1440px] mx-auto grid grid-cols-1 gap-16 sm:grid-cols-2 sm:gap-10 xl:grid-cols-3 xl:gap-8">
            {relatedNews && relatedNews.map((related_news, index) => (
              <div key={index}>
                <img
                  src={related_news.featured_image}
                  alt="blog-thumb"
                  className="rounded-md xl:w-[416px]"
                />
                <p className="my-5 bg-[#CAFF31] text-sm text-[#626262] rounded-[40px] inline-block px-4 py-2">
                  {related_news.cat_name}
                </p>
                <h1 className="pb-6 text-xl xl:text-2xl">{related_news.short_description}</h1>
                <Link to={'/blog-template'} state={{
                    id: related_news.ID 
                  }} className="flex items-center gap-6">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="43"
                    height="20"
                    viewBox="0 0 43 20"
                    fill="none">
                    <path
                      d="M0.380859 9.85695H41.7675M41.7675 9.85695L32.5541 0.643555M41.7675 9.85695L32.5541 19.0704"
                      stroke="black"
                      strokeWidth="0.820568"
                      strokeLinecap="round"
                    />
                  </svg>
                  Know more
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogTemplate;
