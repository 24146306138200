// "use client";
import React, { useState } from "react";
import { Link } from "react-router-dom";
// import Image from "next/image";
import Logo from "./../assets/images/common/skcl-logo.png";
import RightArrow from "./../assets/images/common/right-arrow.svg";
import useScrollDirection from "./hooks/useScrollDirection";
import MobileNav from "./mobileNav";
import useScrollToHash from "./hooks/useScrolltoHash";

const Navbar = () => {
  // useScrollToHash();
  const [isActive, setIsActive] = useState(false);
  // const scrollDirection = useScrollDirection();

  const pageUrls = [
    {
      name: "About",
      route: "/about",
    },
    // {
    //   name: "Impact",
    //   route: "/#impact",
    // },
    {
      name: "Value",
      route: "/#value",
    },
    {
      name: "Focus Areas",
      route: "/#focusarea",
    },
    {
      name: "Clientele",
      route: "/clientele",
    },
    {
      name: "Partners",
      route: "/partners",
    },
    {
      name: "Portfolio",
      route: "/#portfolio",
    },
    // {
    //   name: "News",
    //   route: "/blogs",
    // },
    // {
    //   name: "Testimonials",
    //   route: "/#testimonials",
    // },
  ];

  return (
    <>
      <div
        className={`sticky will-change-transform top-0 flex justify-between px-5 lg:px-[40px] xl:px-[80px] py-6 bg-white z-20 transition-transform duration-300 ease-in-out 
          `}>
        <a href="/">
          <img src={Logo} alt="skcl-logo" className="w-[200px] md:w-[210px]" />
        </a>

        <div className="hidden xl:flex items-center gap-[26px]">
          <Link
            to="/about"
            className="text-base font-light text-[#626262]">
            About
          </Link>
          {/* <Link
            to="/#impact"
            className="text-base font-light text-[#626262]">
            Impact
          </Link> */}
          <Link
            to="/#value"
            className="text-base font-light text-[#626262]">
            Value
          </Link>
          <Link
            to="/#focusarea"
            className="text-base font-light text-[#626262]">
            Focus Areas
          </Link>
          <Link
            to="/clientele"
            className="text-base font-light text-[#626262]">
            Clientele
          </Link>
          <Link
            to="/partners"
            className="text-base font-light text-[#626262]">
            Partners
          </Link>
          <Link
            to="/#portfolio"
            className="text-base font-light text-[#626262]">
            Portfolio
          </Link>
          {/* <Link
            to="/blogs"
            className="text-base font-light text-[#626262]">
            News
          </Link>
          <Link
            to="/#testimonials"
            className="text-base font-light text-[#626262]">
            Testimonials
          </Link> */}
          {/* {pageUrls.map(({ name, route }, index) => (
            <Link
              key={index}
              to={route}
              className="text-base font-light text-[#626262]">
              {name}
            </Link>
          ))} */}
          <Link
            to="/apply-now"
            className="items-center hidden gap-4 md:flex whitespace-nowrap">
            <img src={RightArrow} alt="arrow-svg" />
            Apply Now
          </Link>
        </div>
        <button className="xl:hidden" onClick={() => setIsActive(true)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="19"
            viewBox="0 0 28 19"
            fill="none">
            <path
              d="M0.856165 18.2419V16.7732H27.356V18.2419H0.856165ZM0.856165 10.282V8.81329H27.356V10.282H0.856165ZM0.856165 2.32215V0.853418H27.356V2.32215H0.856165Z"
              fill="#626262"
              stroke="white"
              strokeWidth="0.2"
            />
          </svg>
        </button>
      </div>

      {isActive && <MobileNav props={setIsActive} />}
    </>
  );
};

export default Navbar;

// For Nav stickyness
// ${
//   scrollDirection === "down" ? "-translate-y-full" : "translate-y-0"
// }
