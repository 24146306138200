// "use client";
import React, { useRef } from "react";
// import Image from "next/image";
import { motion, useScroll, useTransform, useInView } from "framer-motion";
import image1 from "./../../assets/images/home/turbo-hire.svg";
import image2 from "./../../assets/images/home/nimble-box.svg";
import image3 from "./../../assets/images/home/mindler.svg";
import image4 from "./../../assets/images/home/quick-shift.svg";
import image5 from "./../../assets/images/home/sasvitha.svg";
import image6 from "./../../assets/images/home/plugzmart.svg";
import image7 from "./../../assets/images/home/my3dmeta.svg";
import image8 from "./../../assets/images/home/degpeg.svg";
import image9 from "./../../assets/images/home/raptee.svg";
import image10 from "./../../assets/images/home/trelish.svg";
import image11 from "./../../assets/images/home/nutrizoe.svg";
import image12 from "./../../assets/images/home/stage.svg";
import image13 from "./../../assets/images/home/milky-mist.svg";
import image14 from "./../../assets/images/home/tune.svg";
import PortfolioBg from "./../../assets/images/home/portfolio-bg.svg";
import DottedBg from "./../../assets/images/home/dotted-bg.png";
import SlideUp from ".../../../slideUp";

const VertiSlide = () => {
  const imgArr1 = [image1, image2, image3, image4, image5, image13, image12];
  const imgArr2 = [image6, image14, image7, image8, image9, image10, image11];
  const container = useRef();
  const { scrollYProgress } = useScroll({
    target: container,
    offset: ["start end", "end start"],
  });

  // For parallex scroll
  // const y1 = useTransform(scrollYProgress, [0.5, 1], [900, -2850]);
  // const y2 = useTransform(scrollYProgress, [0.2, 1], [-3250, 1450]);

  const y1 = useTransform(scrollYProgress, [0.5, 1], [190, -2650]);
  const y2 = useTransform(scrollYProgress, [0.2, 1], [-2950, 2950]);

  // For Transition
  const parentRef = useRef();
  const isInView = useInView(parentRef);

  const parentRef2 = useRef();

  const slideUpAnimation = {
    hidden: { y: "80%", opacity: 0 },
    visible: { y: 0, opacity: 1, transition: { duration: 0.8 } },
  };

  const slideUpAnimation2 = {
    hidden: { y: "-100%", opacity: 0 },
    visible: { y: 0, opacity: 1, transition: { duration: 1 } },
  };

  return (
    <div className="relative hidden lg:block">
      <section className="sticky top-0 h-screen overflow-hidden">
        <motion.div
          initial={{ opacity: 0, x: -450 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 1 }}
          viewport={{ once: true }}>
          <img
            src={PortfolioBg}
            alt="portfolio-bg"
            className="absolute top-[-200px] -z-10 w-full"
          />
        </motion.div>

        <img
          src={DottedBg}
          alt="portfolio-bg"
          className="absolute top-[-200px] z-[-12] w-full h-[-webkit-fill-available] object-cover"
        />
        <div className="flex justify-end gap-40 pr-[80px]">
          <div className="flex items-center h-[100vh]">
            <h1 className="absolute lg:left-[140px] xl:left-[300px] text-[56px] leading-[64px] w-[300px] font-bold">
              <span>
                <SlideUp custom={1}>Our Portfolio</SlideUp>
              </span>
            </h1>
          </div>

          <div className="left-0 z-10 flex items-start gap-10">
            <motion.div
              className="h-[100%] overflow-hidden"
              initial="hidden"
              animate={isInView ? "visible" : "hidden"}
              variants={slideUpAnimation}>
              <motion.div style={{ y: y1 }} className="flex flex-col gap-5">
                {imgArr1.map((src, i) => (
                  <img key={i} src={src} alt="imgs" className="rounded-md " />
                ))}
              </motion.div>
            </motion.div>

            <motion.div
              className="h-[100%] overflow-hidden"
              initial="hidden"
              animate={isInView ? "visible" : "hidden"}
              variants={slideUpAnimation2}>
              <motion.div style={{ y: y2 }} className="flex flex-col gap-5">
                {imgArr2.map((src, i) => (
                  <img key={i} src={src} alt="imgs" className="rounded-md " />
                ))}
              </motion.div>
            </motion.div>
          </div>
        </div>
      </section>

      <div className="h-[300vh]" ref={parentRef}></div>
    </div>
  );
};

export default VertiSlide;
